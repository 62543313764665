import 'bootstrap/dist/css/bootstrap.css';
import { useState, useEffect } from 'react';
import { getData, getMakeModelSearch } from '../utils/utils';
import * as React from 'react';
import { tokenCheck } from '../utils/utils';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
import {debounce} from 'lodash';
import { Collapse } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
// Material Ui Imports
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import { useTheme, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import { DataGrid , GridToolbarContainer , GridToolbarExport } from '@mui/x-data-grid';
import { Link } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Modal } from 'react-bootstrap';

// React Virtualized for optimization
const LISTBOX_PADDING = 8; // px

    function renderRow(props) {
        const { data, index, style } = props;
        const dataSet = data[index];
        const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING,
        };
    
        if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
            {dataSet.group}
            </ListSubheader>
        );
        }
    
        return (
        <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
            {dataSet[1]}
        </Typography>
        );
    }

    const OuterElementContext = React.createContext({});

    const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
    });

    function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
    }

    // Adapter for react-window
    const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
        const { children, ...other } = props;
        const itemData = [];
        children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
        });
    
        const theme = useTheme();
        const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
        });
    
        const itemCount = itemData.length;
        const itemSize = smUp ? 36 : 48;
    
        const getChildSize = (child) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }
    
        return itemSize;
        };
    
        const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
        };
    
        const gridRef = useResetCache(itemCount);
    
        return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
            <VariableSizeList
                itemData={itemData}
                height={getHeight() + 2 * LISTBOX_PADDING}
                width="100%"
                ref={gridRef}
                outerElementType={OuterElementType}
                innerElementType="ul"
                itemSize={(index) => getChildSize(itemData[index])}
                overscanCount={5}
                itemCount={itemCount}
            >
                {renderRow}
            </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
        );
    });
    
    ListboxComponent.propTypes = {
        children: PropTypes.node,
    };
    
    function random(length) {
        const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
    
        for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
    
        return result;
    }
    
    const StyledPopper = styled(Popper)({
        [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
        },
    });
    // React Virtualized for optimization

        // For Overflowing table text
        function isOverflown(element) {
            return (
            element.scrollHeight > element.clientHeight ||
            element.scrollWidth > element.clientWidth
            );
        }
        
        const GridCellExpand = React.memo(function GridCellExpand(props) {
            const { width, value } = props;
            const wrapper = React.useRef(null);
            const cellDiv = React.useRef(null);
            const cellValue = React.useRef(null);
            const [anchorEl, setAnchorEl] = React.useState(null);
            const [showFullCell, setShowFullCell] = React.useState(false);
            const [showPopper, setShowPopper] = React.useState(false);
        
            const handleMouseEnter = () => {
            const isCurrentlyOverflown = isOverflown(cellValue.current);
            setShowPopper(isCurrentlyOverflown);
            setAnchorEl(cellDiv.current);
            setShowFullCell(true);
            };
        
            const handleMouseLeave = () => {
            setShowFullCell(false);
            };
        
            React.useEffect(() => {
            if (!showFullCell) {
                return undefined;
            }
        
            function handleKeyDown(nativeEvent) {
                // IE11, Edge (prior to using Bink?) use 'Esc'
                if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
                }
            }
        
            document.addEventListener('keydown', handleKeyDown);
        
            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
            }, [setShowFullCell, showFullCell]);
        
            return (
            <Box
                ref={wrapper}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                alignItems: 'center',
                lineHeight: '24px',
                width: 1,
                height: 1,
                position: 'relative',
                display: 'flex',
                }}
            >
                <Box
                ref={cellDiv}
                sx={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
                />
                <Box
                ref={cellValue}
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                {value}
                </Box>
                {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17 }}
                >
                    <Paper
                    elevation={1}
                    style={{ minHeight: wrapper.current.offsetHeight - 3 }}
                    >
                    <Typography variant="body2" style={{ padding: 8 }}>
                        {value}
                    </Typography>
                    </Paper>
                </Popper>
                )}
            </Box>
            );
        });
        
        GridCellExpand.propTypes = {
            value: PropTypes.string.isRequired,
            width: PropTypes.number.isRequired,
        };
        
        function renderCellExpand(params) {
            return (
            <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
            );
        }
        
        renderCellExpand.propTypes = {
            /**
             * The column of the row that the current cell belongs to.
             */
            colDef: PropTypes.object.isRequired,
            /**
             * The cell value, but if the column has valueGetter, use getValue.
             */
            value: PropTypes.string,
        };
        
        const columns = [
            { field: 'col1', headerName: 'Column 1', width: 80, renderCell: renderCellExpand },
            {
            field: 'col2',
            headerName: 'Column 2',
            width: 100,
            renderCell: renderCellExpand,
            },
            {
            field: 'col3',
            headerName: 'Column 3',
            width: 150,
            renderCell: renderCellExpand,
            },
        ];
    // For Overflowing table text


    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

const MamSearch = () => {
    // State Management
    const [makeSearch,setMakeSearch] = useState("");
    const [modelSearch,setModelSearch] = useState("");
    const [oldestYearSearch,setOldestYearSearch] = useState(0);
    const [latestYearSearch,setLatestYearSearch] = useState(1000000000000);
    const [minLengthSearch,setMinLengthSearch] = useState(0);
    const [maxLengthSearch,setMaxLengthSearch] = useState(1000000000000);
    const [minPriceSearch,setMinPriceSearch] = useState(0);
    const [maxPriceSearch,setMaxPriceSearch] = useState(1000000000000);
    const [conditionSearch,setConditionSearch] = useState("");
    const [countrySearch,setCountrySearch] = useState("");
    const [hullSearch,setHullSearch] = useState("");
    // const [order,setOrder] = useState("ASC");
    const [search, setSearch] = useState([{}]);
    const [loading,setLoading] = useState(false);
    const [button,setButton] = useState(false);
    const [BoatMakes,setBoatMakes] = useState([])
    const [model,setModel] = useState([])
    const [hullType,setHullType] = useState([])
    const [country,setCountry] = useState([])
    const [open, setOpen] = useState(true);
    const [show,setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showModal,setShowModal] = useState(false)
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const [spinner,setSpinner] = React.useState(false)

    // Used for validated requests (so unauthorised users cant steal data)
    const authorizeToken = localStorage.getItem('MyToken')
    // Handler for presenting data to front end
    const searchHandler = async () => {
        setButton(true)
        const res = await getData(`${process.env.REACT_APP_REST_API}boats-only`, authorizeToken)
        const data = res.filter(x => x.BoatMake !== null)
        // const modelData = await getData(`${process.env.REACT_APP_REST_API}get-model`, authorizeToken)
        // const modelDataFilter = modelData.filter(x => x.Model !== null)
        // const hullData = await getData(`${process.env.REACT_APP_REST_API}get-hull-type`, authorizeToken)
        // const hullDataFilter = hullData.filter(x => x.Hull_Type !== null)
        const countryData = await getData(`${process.env.REACT_APP_REST_API}get-country`, authorizeToken)
        const countryDataFilter = countryData.filter(x => x.CountryName !== null)
        setBoatMakes(data)
        // setModel(modelDataFilter)
        // setHullType(hullDataFilter)
        setCountry(countryDataFilter)
    }
     // Runs handler on page load
        React.useEffect(() => {
        searchHandler()
    },[])
    React.useEffect(() => {
        if(search.length === 0){
            handleShow()
        }else{
            handleClose()
        }
    },[search.length])
    const searchRequest = async () => {
        try {
            setSpinner(true)
            if(makeSearch === ""){
                handleShowModal()
                setSpinner(false)
                return
            }
            const res = await getMakeModelSearch(`${process.env.REACT_APP_REST_API}retrieve-make`,authorizeToken,'%' + makeSearch,'%' + modelSearch,'%' + conditionSearch,'%' + hullSearch,'%' + countrySearch)
            setSearch(res)
            setSpinner(false)
        } catch (error) {
            console.log(error)
            console.log("closing")
        }
    }
    const conditionArray = [
        {Condition: "Used"},
        {Condition: "Unknown"},
        {Condition: "New"},
        {Condition: "Charter"},
    ]
    const hullArray = [
        {Hull_Type: "Aluminium"},
        {Hull_Type: "Ferro-Cement"},
        {Hull_Type: "Fibreglass"},
        {Hull_Type: "Hypalon"},
        {Hull_Type: "Other"},
        {Hull_Type: "PVC"},
        {Hull_Type: "Steel"},
        {Hull_Type: "Wood"},
        {Hull_Type: "Unknown"},
    ]
    // Handlers for smoother filtering (Less Renders)
    const changeMakeHandler = (event,val) => {
        setMakeSearch(val);
    };
    const debouncedMakeHandler = React.useMemo(
        () => debounce(changeMakeHandler, 500)
    , []);

    const changeModelHandler = (event,val) => {
        setModelSearch(val);
    };
    const debouncedModelHandler = React.useMemo(
        () => debounce(changeModelHandler, 500)
    , []);
    const changeConditionHandler = (event,val) => {
        setConditionSearch(val);
    };
    const debouncedConditionHandler = React.useMemo(
        () => debounce(changeConditionHandler, 500)
    , []);
    const changeHullHandler = (event,val) => {
        setHullSearch(val);
    };
    const debouncedHullHandler = React.useMemo(
        () => debounce(changeHullHandler, 500)
    , []);
    const changeCountryHandler = (event,val) => {
        setCountrySearch(val);
    };
    const debouncedCountryHandler = React.useMemo(
        () => debounce(changeCountryHandler, 500)
    , []);
    const changeOldestYearHandler = (event,val) => {
        setOldestYearSearch(val);
    };
    const debouncedOldestYearHandler = React.useMemo(
        () => debounce(changeOldestYearHandler, 500)
    , []);
    const changeLatestYearHandler = (event,val) => {
        setLatestYearSearch(val);
    };
    const debouncedLatestYearHandler = React.useMemo(
        () => debounce(changeLatestYearHandler, 500)
    , []);
    const changeMinLengthHandler = (event,val) => {
        setMinLengthSearch(val);
    };
    const debouncedMinLenthHandler = React.useMemo(
        () => debounce(changeMinLengthHandler, 500)
    , []);
    const changeMaxLengthHandler = (event,val) => {
        setMaxLengthSearch(val);
    };
    const debouncedMaxLengthHandler = React.useMemo(
        () => debounce(changeMaxLengthHandler, 500)
    , []);
    const changeMinPriceHandler = (event,val) => {
        setMinPriceSearch(val);
    };
    const debouncedMinPriceHandler = React.useMemo(
        () => debounce(changeMinPriceHandler, 500)
    , []);
    const changeMaxPriceHandler = (event,val) => {
        setMaxPriceSearch(val);
    };
    const debouncedMaxPriceHandler = React.useMemo(
        () => debounce(changeMaxPriceHandler, 500)
    , []);
    
    // Search filter functionality
    const searchMake = (rows) => {
        if(oldestYearSearch === "" && latestYearSearch === "" && minLengthSearch === "" && maxLengthSearch === "" && minPriceSearch === "" && maxPriceSearch === ""){
            return rows
        } else if (
            oldestYearSearch <= rows.Year && latestYearSearch >= rows.Year
            && minLengthSearch <= rows.Length && maxLengthSearch >= rows.Length
            && minPriceSearch <= rows.Price && maxPriceSearch >= rows.Price){
            return rows
        }
    }
    // Conditional rendering (if page is loading use this)
    // if( !search.length > 0 || loading){
    //     return (
    //         <div className="middlePage">
    //             {button ? (
    //             <Box sx={{ display: 'flex' }}>
    //             <CircularProgress />
    //             </Box>
    //             ):(<Button variant="contained" onClick={searchHandler}>Search Boats</Button>)}
    //         </div>
    //     )
    // }

    // Ensures States return to original number if field is empty
    if( maxPriceSearch === ""){
        setMaxPriceSearch(1000000000000)
    }
    if( maxLengthSearch === ""){
        setMaxLengthSearch(1000000000000)
    }
    if( latestYearSearch === ""){
        setLatestYearSearch(1000000000000)
    }

    const columns = [
        {field:"Make", HeaderName:"Make",flex:1,renderCell: renderCellExpand, minWidth:200,},
        {field:"Model", HeaderName:"Model",flex:1,renderCell: renderCellExpand, minWidth:200,},
        {field:"Condition", HeaderName:"Condition",flex:1,renderCell: renderCellExpand, minWidth:150,},
        {field:"Year", HeaderName:"Year",flex:1,renderCell: renderCellExpand, minWidth:100,},
        {field:"Hull Type", HeaderName:"Hull Type",flex:1,renderCell: renderCellExpand, minWidth:150,},
        {field:"Length", HeaderName:"Length",flex:1,renderCell: renderCellExpand, minWidth:100,},
        {field:"Price", HeaderName:"Price",flex:1,renderCell: renderCellExpand,minWidth:150,},
        {field:"Country", HeaderName:"Country",flex:1,renderCell: renderCellExpand,minWidth:200,},
        {field:"Location", HeaderName:"Location",flex:1,renderCell: renderCellExpand,minWidth:200,},
        {field:"URL", HeaderName:"URL",flex:1, minWidth:400,renderCell: (cellValues) => {
            return <Link href={`${cellValues.row.URL}`} target='_blank'>{cellValues.row.URL}</Link>;
        }},
    ]
    return (
        // All Search engine Boxes
        <div>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please Select a Make</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
            <Button onClick={() => setOpen(!open)} aria-controls="collapse" aria-expanded={open}>
                {!open ? "Show Filters" : "Close Filters"}
            </Button>
            <div>
            <Collapse in={open}>
                <div id="collapse">
                <p className='center'>Make Filters</p>
                <div className='Autocomplete' >
                    <Stack spacing={2} sx={{ width: 250 }} className='padding'>
                        <Autocomplete
                            id="virtualize-demo"
                            disableListWrap
                            freeSolo
                            PopperComponent={StyledPopper}
                            ListboxComponent={ListboxComponent}
                            onInputChange={debouncedMakeHandler}
                            options={[...new Set(BoatMakes.sort((a, b) => a.BoatMake.localeCompare(b.BoatMake)).map((option) => option.BoatMake))]}
                            groupBy={(option) => option[0]}
                            renderInput={(params) => <TextField {...params} label="Make"/>}
                            renderOption={(props, option) => [props, option]}
                            renderGroup={(params) => params}
                        />
                    </Stack>
                    <Stack spacing={2} sx={{ width: 250 }} className='padding'>
                        <Autocomplete
                            id="virtualize-demo"
                            disableListWrap
                            freeSolo
                            onInputChange={debouncedModelHandler}
                            PopperComponent={StyledPopper}
                            ListboxComponent={ListboxComponent}
                            options={[...new Set(model.slice(0,0).map((option) => option.Model))]}
                            // groupBy={(option) => option[0]}
                            renderInput={(params) => <TextField {...params} label="Model" />}
                            renderOption={(props, option) => [props, option]}
                            renderGroup={(params) => params}
                        />
                    </Stack>
                    <Stack spacing={2} sx={{ width: 250 }} className='padding'>
                        <Autocomplete
                            id="virtualize-demo"
                            disableListWrap
                            freeSolo
                            onInputChange={debouncedConditionHandler}
                            PopperComponent={StyledPopper}
                            ListboxComponent={ListboxComponent}
                            options={[...new Set(conditionArray.sort((a, b) => a.Condition.localeCompare(b.Condition)).map((option) => option.Condition))]}
                            // groupBy={(option) => option[0]}
                            renderInput={(params) => <TextField {...params} label="Condition" />}
                            renderOption={(props, option) => [props, option]}
                            renderGroup={(params) => params}
                        />
                    </Stack>
                    <Stack spacing={2} sx={{ width: 250 }} className='padding'>
                        <Autocomplete
                            id="virtualize-demo"
                            disableListWrap
                            freeSolo
                            onInputChange={debouncedHullHandler}
                            PopperComponent={StyledPopper}
                            ListboxComponent={ListboxComponent}
                            options={[...new Set(hullArray.sort((a, b) => a.Hull_Type.localeCompare(b.Hull_Type)).map((option) => option.Hull_Type))]}
                            // groupBy={(option) => option[0]}
                            renderInput={(params) => <TextField {...params} label="Hull Type" />}
                            renderOption={(props, option) => [props, option]}
                            renderGroup={(params) => params}
                        />
                    </Stack>
                    <Stack spacing={2} sx={{ width: 250 }} className='padding'>
                        <Autocomplete
                            id="virtualize-demo"
                            disableListWrap
                            freeSolo
                            onInputChange={debouncedCountryHandler}
                            PopperComponent={StyledPopper}
                            ListboxComponent={ListboxComponent}
                            options={[...new Set(country.sort((a, b) => a.CountryName.localeCompare(b.CountryName)).map((option) => option.CountryName))]}
                            // groupBy={(option) => option[0]}
                            renderInput={(params) => <TextField {...params} label="Country" />}
                            renderOption={(props, option) => [props, option]}
                            renderGroup={(params) => params}
                        />
                    </Stack>
                </div>
                <p className='center'>Range Filters</p>
                <div className='Autocomplete'>
                    <Stack spacing={2} sx={{ width: 150 }} className='padding'>
                        <Autocomplete
                            freeSolo
                            onInputChange={debouncedOldestYearHandler}
                            options={[...new Set(search.slice(0,0).map((option) => option.Year))]}
                            renderInput={(params) => <TextField {...params} label="Oldest Year"/>}
                        />
                    </Stack>
                    <Stack spacing={2} sx={{ width: 150 }} className='padding'>
                        <Autocomplete
                            freeSolo
                            onInputChange={debouncedLatestYearHandler}
                            options={[...new Set(search.slice(0,0).map((option) => option.Year))]}
                            renderInput={(params) => <TextField {...params} label="Latest Year"/>}
                        />
                    </Stack>
                    <Stack spacing={2} sx={{ width: 150 }} className='padding'>
                        <Autocomplete
                            freeSolo
                            onInputChange={debouncedMinLenthHandler}
                            options={[...new Set(search.slice(0,0).map((option) => option.Length))]}
                            renderInput={(params) => <TextField {...params} label="Min Length"/>}
                        />
                    </Stack>
                    <Stack spacing={2} sx={{ width: 150 }} className='padding'>
                        <Autocomplete
                            freeSolo
                            onInputChange={debouncedMaxLengthHandler}
                            options={[...new Set(search.slice(0,0).map((option) => option.Length))]}
                            renderInput={(params) => <TextField {...params} label="Max Length"/>}
                        />
                    </Stack>
                    <Stack spacing={2} sx={{ width: 150 }} className='padding'>
                        <Autocomplete
                            freeSolo
                            onInputChange={debouncedMinPriceHandler}
                            options={[...new Set(search.slice(0,0).map((option) => option.Price))]}
                            renderInput={(params) => <TextField {...params} label="Min Price"/>}
                        />
                    </Stack>
                    <Stack spacing={2} sx={{ width: 150 }} className='padding'>
                        <Autocomplete
                            freeSolo
                            onInputChange={debouncedMaxPriceHandler}
                            options={[...new Set(search.slice(0,0).map((option) => option.Price))]}
                            renderInput={(params) => <TextField {...params} label="Max Price"/>}
                        />
                    </Stack>
                </div>
                <Box textAlign='center'>
                {!spinner ? 
                <Button onClick={searchRequest}>Search Boats</Button>
                :
                <Box sx={{ width: '100%'}}>
                <LinearProgress />
                </Box>
                }
                <Alert show={show} variant="danger" className='center margin'>No Results</Alert>
                </Box>
                </div>
                </Collapse>
                </div>
            <div style={{ height: '75vh', width: '100%' }}>
                <DataGrid
                    rows={search.filter(searchMake)}
                    columns={columns}
                    getRowId={e => e.GaplessIndex}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    checkboxSelection
                    disableSelectionOnClick
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    disableColumnFilter 
                    sx={{
                        '& .MuiDataGrid-cell': {
                            overflow: "hidden",
                            whiteSpace: "break-spaces",
                            fontSize:13,
                            fontWeight:600
                        },
                        '& .MuiDataGrid-columnHeader':{
                            fontSize:14,
                            fontWeight:600
                        }
                    }}
                    />
            </div>
        </div>
    )
}

export default MamSearch;
