import * as React from 'react';
import { getData } from "../utils/utils";
import { useState } from "react";
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
import { Link } from '@mui/material';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
// Mui imports
import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import { useTheme, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton  } from '@mui/x-data-grid';
import { NavLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';



// React Virtualized for optimization
const LISTBOX_PADDING = 8; // px

    function renderRow(props) {
        const { data, index, style } = props;
        const dataSet = data[index];
        const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING,
        };
    
        if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
            {dataSet.group}
            </ListSubheader>
        );
        }
    
        return (
        <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
            {dataSet[1]}
        </Typography>
        );
    }

    const OuterElementContext = React.createContext({});

    const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
    });

    function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
    }

    // Adapter for react-window
    const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
        const { children, ...other } = props;
        const itemData = [];
        children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
        });
    
        const theme = useTheme();
        const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
        });
    
        const itemCount = itemData.length;
        const itemSize = smUp ? 36 : 48;
    
        const getChildSize = (child) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }
    
        return itemSize;
        };
    
        const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
        };
    
        const gridRef = useResetCache(itemCount);
    
        return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
            <VariableSizeList
                itemData={itemData}
                height={getHeight() + 2 * LISTBOX_PADDING}
                width="100%"
                ref={gridRef}
                outerElementType={OuterElementType}
                innerElementType="ul"
                itemSize={(index) => getChildSize(itemData[index])}
                overscanCount={5}
                itemCount={itemCount}
            >
                {renderRow}
            </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
        );
    });
    
    ListboxComponent.propTypes = {
        children: PropTypes.node,
    };
    
    function random(length) {
        const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
    
        for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
    
        return result;
    }
    
    const StyledPopper = styled(Popper)({
        [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
        },
    });
    // React Virtualized for optimization

    // For Overflowing table text
        function isOverflown(element) {
            return (
            element.scrollHeight > element.clientHeight ||
            element.scrollWidth > element.clientWidth
            );
        }
        
        const GridCellExpand = React.memo(function GridCellExpand(props) {
            const { width, value } = props;
            const wrapper = React.useRef(null);
            const cellDiv = React.useRef(null);
            const cellValue = React.useRef(null);
            const [anchorEl, setAnchorEl] = React.useState(null);
            const [showFullCell, setShowFullCell] = React.useState(false);
            const [showPopper, setShowPopper] = React.useState(false);
        
            const handleMouseEnter = () => {
            const isCurrentlyOverflown = isOverflown(cellValue.current);
            setShowPopper(isCurrentlyOverflown);
            setAnchorEl(cellDiv.current);
            setShowFullCell(true);
            };
        
            const handleMouseLeave = () => {
            setShowFullCell(false);
            };
        
            React.useEffect(() => {
            if (!showFullCell) {
                return undefined;
            }
        
            function handleKeyDown(nativeEvent) {
                // IE11, Edge (prior to using Bink?) use 'Esc'
                if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
                }
            }
        
            document.addEventListener('keydown', handleKeyDown);
        
            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
            }, [setShowFullCell, showFullCell]);
        
            return (
            <Box
                ref={wrapper}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                alignItems: 'center',
                lineHeight: '24px',
                width: 1,
                height: 1,
                position: 'relative',
                display: 'flex',
                }}
            >
                <Box
                ref={cellDiv}
                sx={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
                />
                <Box
                ref={cellValue}
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                {value}
                </Box>
                {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17 }}
                >
                    <Paper
                    elevation={1}
                    style={{ minHeight: wrapper.current.offsetHeight - 3 }}
                    >
                    <Typography variant="body2" style={{ padding: 8 }}>
                        {value}
                    </Typography>
                    </Paper>
                </Popper>
                )}
            </Box>
            );
        });
        
        GridCellExpand.propTypes = {
            value: PropTypes.string.isRequired,
            width: PropTypes.number.isRequired,
        };
        
        function renderCellExpand(params) {
            return (
            <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
            );
        }
        
        renderCellExpand.propTypes = {
            /**
             * The column of the row that the current cell belongs to.
             */
            colDef: PropTypes.object.isRequired,
            /**
             * The cell value, but if the column has valueGetter, use getValue.
             */
            value: PropTypes.string,
        };
        
        const columns = [
            { field: 'col1', headerName: 'Column 1', width: 80, renderCell: renderCellExpand },
            {
            field: 'col2',
            headerName: 'Column 2',
            width: 100,
            renderCell: renderCellExpand,
            },
            {
            field: 'col3',
            headerName: 'Column 3',
            width: 150,
            renderCell: renderCellExpand,
            },
        ];
    // For Overflowing table text

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }


function Sellers() {
    const [search, setSearch] = useState([]);
    const [loading,setLoading] = useState(false);
    const [num,setNum] = useState(1);   
    const [show, setShow] = React.useState(false);
    const [boatMake,setBoatMake] = React.useState([]);
    const [boatType,setBoatType] = React.useState([]);
    const [boatLocation,setBoatLocation] = React.useState([]);
    const [sellerType,setSellerType] = React.useState([]);
    const [sellerOnly,setSellerOnly] = React.useState([])
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // Authorize the request
    const authorizeToken = localStorage.getItem('MyToken')
    // Handler for presenting data to front end
    const searchHandler = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}retrieve-sellers`,authorizeToken)
        setSearch(res)
    }
    const BoatMakeHandler = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}boat-make-join-where/${num}`,authorizeToken)
        setBoatMake(res)
    }
    const BoatTypeHandler = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}boat-type-join-where/${num}`,authorizeToken)
        setBoatType(res)
    }
    const BoatLocationHandler = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}boat-location-join-where/${num}`,authorizeToken)
        setBoatLocation(res)
    }
    const sellerTypeHandler = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}seller-type-join-where/${num}`,authorizeToken)
        setSellerType(res)
    }
    const sellerOnlyHandler = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}sellers-only-where/${num}`,authorizeToken)
        setSellerOnly(res)
    }
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
    React.useEffect(() => {
        searchHandler()
    },[])
    React.useEffect(() => {
        BoatMakeHandler()
        BoatTypeHandler()
        BoatLocationHandler()
        sellerTypeHandler()
        sellerOnlyHandler()
    },[num])

    const modalHandler = () => {
        handleShow()
    };

    // Conditional rendering (if page is loading use this)
    if( !search.length > 0 || loading){
        return (
            <div className="middlePage">
                <Box sx={{ display: 'flex' }}>
                <CircularProgress />
                </Box>
            </div>
        )
    }

    const columns = [
        // {field:'SellersID', headerName:'SellerID', flex:1, minWidth:60},
        {field:'CompanyName', headerName:'Company Name', flex:1, minWidth:250,renderCell: (cellValues) => {
            return <Link className='pointer' onClick={() =>{modalHandler(); setNum(cellValues.row.SellersID);}} data-toggle="tooltip" data-placement="top" title="Click For More Info">{cellValues.row.CompanyName}</Link>;
        },},
        {field:'Email', headerName:'Email', flex:1, minWidth:300,renderCell: renderCellExpand,},
        {field:'Phone', headerName:'Phone Number', flex:1, minWidth:200,renderCell: renderCellExpand,},
        {field:'Website', headerName:'Website', flex:1, renderCell: (cellValues) => {
            return <Link href={`${cellValues.row.Website}`} target='_blank'>{cellValues.row.Website}</Link>;
        }, minWidth:250},
        {field:'NumberOfBoats', headerName:'No. Of Boats', minWidth:150,renderCell: renderCellExpand,},
        {field:'LowestValue95PerCentGBP', headerName:'LowestValue95PerCentGBP', flex:1, minWidth:250,renderCell: renderCellExpand,},
        {field:'HighestValue95PerCentGBP', headerName:'HighestValue95PerCentGBP', flex:1, minWidth:250,renderCell: renderCellExpand,},
        {field:'NotesOnSellers', headerName:'Notes', flex:1, minWidth:400,renderCell: renderCellExpand,},
    ];
    return(
        <div>
            <Modal show={show} onHide={handleClose} scrollable>
                <Modal.Header closeButton>
                <Modal.Title><div className='center'>
                    {sellerOnly.map((row) => {
                        return <p className='largeFont'>{row.CompanyName}</p>
                    })}
                </div></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center'>
                        <p className='underlined'>Seller Type</p>
                        {sellerType.map((row) => {
                                return (
                                <div>
                                    <p>{row.Seller_Type}</p>
                                </div>
                                )
                            })}
                        </div>
                    </Item>
                    </Grid>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center'>
                        <p className='underlined'>Sub Types listed by the seller</p>
                            {boatType.map((row) => {
                                return (
                                <div>
                                    <p>{row.SubType}</p>
                                </div>
                                )
                            })}
                        </div>
                    </Item>
                    </Grid>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center'>
                            <p className='underlined'>Makes the seller specialises in</p>
                            {boatMake.map((row) => {
                                return(
                                <div>
                                    <p>{row.BoatMake}</p>
                                </div>
                                )
                            })}
                        </div>
                    </Item>
                    </Grid>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center'>
                            <p className='underlined'>Locations</p>
                            {boatLocation.map((row) => {
                                return (
                                <div>
                                    <p>{row.ContinentName}, {row.CountryName} {row.RegionName}</p>
                                </div>
                                )
                            })}
                        </div>
                    </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
            <div style={{ height: '75vh', width: '100%' }}>
                <DataGrid
                    rows={search}
                    columns={columns}
                    getRowId={e => e.SellersID}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    checkboxSelection
                    disableSelectionOnClick
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            overflow: "hidden",
                            whiteSpace: "break-spaces",
                            fontSize:13,
                            fontWeight:600
                        },
                        '& .MuiDataGrid-columnHeader':{
                            fontSize:14,
                            fontWeight:600
                        }
                    }}
                    />
            </div>
        </div>  
    )
}

export default Sellers