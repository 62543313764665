// Fetches from The back end will be placed in here
export const getData = async (url,token) => {
    try {
        const response = await fetch(url,{
        method:'GET',
        headers: {
            'Content-Type': 'application/json', Authorization: `Bearer ${token}`
        }
        })
        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error)
    }
}
export const getMakeModelSearch = async (url,token,Make,Model,Condition,Hull_Type,Country) => {
    try {
        const response = await fetch(url,{
            method: "POST",
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            body: JSON.stringify({
                Make: Make,
                Model: Model,
                Condition: Condition,
                Hull_Type: Hull_Type,
                Country: Country,
            })
        })
        const data = await response.json();
        return data ;
    } catch (error) {
        console.log(error)
    }
}
export const searchFormFetch = async (url,token,SubType,NumberOfBoats,LowestValue95PerCentGBP,HighestValue95PerCentGBP,SellerType,Continent,Country,BoatMake) => {
    try {
        const response = await fetch(url,{
            method: "POST",
            headers: {'Content-Type': 'application/json', Authorization: `Bearer ${token}`},
            body: JSON.stringify({
                SubType: SubType,
                NumberOfBoats: NumberOfBoats,
                LowestValue95PerCentGBP: LowestValue95PerCentGBP,
                HighestValue95PerCentGBP: HighestValue95PerCentGBP,
                SellerType: SellerType,
                Continent: Continent,
                Country: Country,
                BoatMake: BoatMake
            })
        })
        const data = await response.json();
        return data ;
    } catch (error) {
        console.log(error)
    }
}
export const signUpFetch = async (email,password) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_REST_API}user/addUser`,{
            method: "POST",
            headers: {"Content-type": "application/json" },
            body: JSON.stringify({
                Email: email,
                Password: password
            })
        })
        const data = await response.json();
        localStorage.setItem("MyToken",data.token);
        return data ;
    } catch (error) {
        console.log(error)
    }
}

export const signInFetch = async (email, password) => {
    try {
    const response = await fetch(`${process.env.REACT_APP_REST_API}user/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
        Email: email,
        Password: password,
        }),
    });
    const data = await response.json();
    localStorage.setItem("MyToken",data.token);
    return data;
    } catch (error) {
    console.log(error);
    }
};

export const updateFetch = async (email,password,token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_REST_API}user/updatePass/${email}`,{
            method: "PUT",
            headers: {"Content-type": "application/json", Authorization: `Bearer ${token}`  },
            body: JSON.stringify({
                Email: email,
                Password: password
            })
        })
        const data = await response.json();
        return data ;
    } catch (error) {
        console.log(error)
    }
}

export const forgotFetch = async (email) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_REST_API}user/forgot-password`,{
            method: "POST",
            headers: {"Content-type": "application/json"},
            body: JSON.stringify({
                Email: email,
            })
        })
        const data = await response.json();
        return data ;
    } catch (error) {
        console.log(error)
    }
}

export const resetFetch = async (id,token,password) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_REST_API}user/reset-password/${id}/${token}`,{
            method: "POST",
            headers: {"Content-type": "application/json"},
            body: JSON.stringify({
                Password: password
            })
        })
        const data = await response.json();
        return data ;
    } catch (error) {
        console.log(error)
    }
}

export const tokenCheck = async (token, setter) => {
    try {
    const response = await fetch(`${process.env.REACT_APP_REST_API}user`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setter(data.user.Email);
    } catch (error) {
    console.log(error);
    }
};
export const tokenCheckCompany = async (token, setter) => {
    try {
    const response = await fetch(`${process.env.REACT_APP_REST_API}user`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setter(data.user.CompanyName);
    } catch (error) {
    console.log(error);
    }
};
export const logOut = async () =>{
    try {
        localStorage.removeItem("MyToken");
    } catch (error) {
        console.log(error);
        alert("Error")
    }
}