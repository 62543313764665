import "./App.css"
import { BrowserRouter, Routes, Route, Navigate, Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import {Nav, Navbar,NavbarBrand,NavDropdown, NavItem} from 'react-bootstrap';
import MamSearch from "./components/ModelSearch";
import Home from "./components/Home";
import NotFound from './components/NotFound';
import Login from './components/Login';
import Sellers from "./components/Sellers";
import SellerSearchForm from "./components/SellerSearchForm";
import ChangePassword from "./components/ChangePassword";
import { logOut, tokenCheckCompany } from "./utils/utils";
import { useState, useEffect } from "react";
import { tokenCheck } from "./utils/utils";
import SellersDetails from "./components/SellersDetails";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import Transport from "./components/Transport";
import SourcesOfFinance from "./components/SourcesOfFinance";
import MarineInsurers from "./components/MarineInsurers";
import MarineSurveyors from "./components/MarineSurveyors";
import MarinasAndHarbours from "./components/MarinasAndHarbours";
import BestSoftware from "./components/BestSoftware";
import BestSoftwareSub from "./components/BestsoftwareSubscription";
import Clients from './components/Clients';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { getData } from "./utils/utils";
import Footer from "./components/Footer";
import BestSoftwareDownload from "./components/BestSoftwareDownload";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

// All Routes are stored here along with the Navbar.
// Bootstrap is being used for the navbar, with dropdown and toggle components.
// Components will be the other webpages.
const App = () => {
  const [search,setSearch] = useState('')
  const [user,setUser] = useState("")
  const [isLogged,setisLogged] = useState(null)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // For Logout Button
  const logOutHandler = async () => {
    await logOut()
    window.location.reload()
  }
  // Token Validation
    useEffect(() => {
      tokenCheck(localStorage.getItem("MyToken"), setUser);
      tokenCheckCompany(localStorage.getItem("MyToken"), setSearch);
  }, []);
  // Conditional render of login and home page varying on if token is validated
  useEffect(() => {
      if(user.length > 0) {
          setisLogged(true)
      }else{
          setisLogged(false)
      }
  },[user])

  return (
      <BrowserRouter>
      {/* User Guide Modal */}
      <div className="viewheight">
      <Modal show={show} onHide={handleClose} scrollable size='xl'>
                    <Modal.Header closeButton>
                    <Modal.Title>BEST User Guide</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center blackFont'>
                          <h2>List of contents</h2>
                            <div>
                              <a href="#homepage">Homepage</a><br/>
                              <a href="#sellers">Sellers</a><br/>
                              <a href="#sellersearchform">Seller Search Form</a><br/>
                              <a href="#transport">Transport</a><br/>
                              <a href="#sourcesoffinance">Sources of Finance</a><br/>
                              <a href="#marineinsurers">Marine Insurers</a><br/>
                              <a href="#marinesurveyors">Marine Surveyors</a><br/>
                              <a href="#marinasandharbours">Marinas & Harbours</a><br/>
                            </div>
                        </div>
                    </Item>
                    </Grid>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center blackFont'>
                            <h2 id="homepage">Homepage</h2>
                            <p>Click the company logo to access the homepage.</p>
                        </div>
                    </Item>
                    </Grid>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center blackFont'>
                            <h2 id="sellers">Sellers</h2>
                            <p>This page will display a table for all sellers in the database. To filter Alphabetically, click a column name at the top, and that column will be filtered. You can also find more information on sellers by clicking the company name or going to their website via link.<br/><br/>If you want more precise options for filtering, click the 3 dots to the right of a column when you hover over, you will be able to hide/show columns, sort by ascending/descending and filter through the data.<br/><br/>Pagination is at the bottom right of the page, it will show you the number of rows in the table and give you the option to go to the next page.<br/><br/>Alternatively, at the top left you can access the filter button, and also an export function which will export all visible rows for printing or into a CSV file (For Microsoft Excel and other compatible applications).</p>
                        </div>
                    </Item>
                    </Grid>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center blackFont'>
                            <h2 id="sellersearchform">SellerSearchForm</h2>
                            <p>This form will let you search more precisely for sellers within your needs. Fill in the filters depending on your choosing and click “search sellers” to find brokers that fit your criteria. The table below works exactly like the Sellers tab. See “Sellers” for more info.</p>
                        </div>
                    </Item>
                    </Grid>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center blackFont'>
                          <h2 id="transport">Transport</h2>
                          <p>This section will provide a table with all available transport companies we have collected in our database.<br/><br/>You can filter by service type with the checkboxes above.<br/><br/>The checkboxes on the left side of the page are there so you can select rows that interest you and then export them via the "export" button<br/><br/>If all text is not visible on your screen within a column, hover over it (or click if you are using mobile) and then you can see the contents of the cell.<br/><br/>The "columns" button is used so you can show or hide columns of relevance to you.</p>
                        </div>
                    </Item>
                    </Grid>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center blackFont'>
                          <h2 id="sourcesoffinance">Sources of Finance</h2>
                          <p>This page will show all financing companies in our database.<br/><br/>The table works exactly the same as "Transport". See “Transport” for help.</p>
                        </div>
                    </Item>
                    </Grid>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center blackFont'>
                          <h2 id="marineinsurers">Marine Insurers</h2>
                          <p>This page will show all Marine Insurers in our database.<br/><br/>The table works exactly the same as "Transport". See “Transport” for help.</p>
                        </div>
                    </Item>
                    </Grid>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center blackFont'>
                          <h2 id="marinesurveyors">Marine Surveyors</h2>
                          <p>This page will show all Marine Surveyors in our database.<br/><br/>The table works exactly the same as "Transport". See “Transport” for help.</p>
                        </div>
                    </Item>
                    </Grid>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center blackFont'>
                          <h2 id="marinasandharbours">Marinas & Harbours</h2>
                          <p>This page will show all Marinas and Harbours in our database.<br/><br/>The table works exactly the same as "Transport". See “Transport” for help.</p>
                        </div>
                    </Item>
                    </Grid>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center blackFont'>
                          <h2 id="makeandmodelsearch">Make and Model Search</h2>
                          <p>This page is used to search for boat makes and models within our database.<br/><br/>You can use the filters to refine your search, and they will be displayed in the table below. Sort by ascending and descending by clicking on the selected column.<br/><br/>When you have selected the boats you are interested in via the checkbox, you can export them via the "export" button</p>
                        </div>
                    </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>
      {/* Navbar */}
      {/* Checks if user is Logged on and depending on true or false, shows different renders */}
      {(isLogged && (
          <Navbar bg="info" variant="light" sticky="top" expand="lg" className="Nav" collapseOnSelect>
            <Navbar.Brand className="logo">
            <Link to="/home"><img src="https://yt3.ggpht.com/HWfTl3On_as01ml97hhj8YKSo3P7DQ_HWffmgEZ_QmROI_bSNMeOsCUL4qBpLSu6uqdj81sAgw=s900-c-k-c0x00ffffff-no-rj" alt="Logo"/></Link>
            </Navbar.Brand>
            <Navbar.Toggle/>
            <Navbar.Collapse>
            <Nav className="margin">
            <NavItem className="padtop">Logged In As {user}</NavItem>
              <NavDropdown title="Sellers" renderMenuOnMount={true}>
                <NavDropdown.Item eventKey="1" as={Link} to='/sellers'>Sellers</NavDropdown.Item>
                <NavDropdown.Divider/>
                <NavDropdown.Item eventKey="2" as={Link} to='/seller-search-form'>Sellers Search Form</NavDropdown.Item>
              </NavDropdown>
              {/* <NavDropdown title="Clients" renderMenuOnMount={true}>
                <NavDropdown.Item eventKey="11" as={Link} to='/clients'>Clients</NavDropdown.Item>
              </NavDropdown> */}
              <NavDropdown title="Services">
                <NavDropdown.Item eventKey="3" as={Link} to="/transport">Transport</NavDropdown.Item>
                <NavDropdown.Divider/>
                <NavDropdown.Item eventKey="4" as={Link} to="/sources-of-finance">Sources of Finance</NavDropdown.Item>
                <NavDropdown.Divider/>
                <NavDropdown.Item eventKey="5" as={Link} to="/marine-insurers">Marine Insurers</NavDropdown.Item>
                <NavDropdown.Divider/>
                <NavDropdown.Item eventKey="6" as={Link} to="/marine-surveyors">Marine Surveyors</NavDropdown.Item>
                <NavDropdown.Divider/>
                <NavDropdown.Item eventKey="7" as={Link} to="/marinas-and-harbours">Marinas & Harbours</NavDropdown.Item>
              </NavDropdown>
            <NavDropdown title="Key Functions">
              <NavDropdown.Item eventKey="8" as={Link} to="/mamsearch"> Make and Model Search</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="User Settings">
              <NavDropdown.Item eventKey="9" as={Link} to='/change-password'>Change Password</NavDropdown.Item>
              <NavDropdown.Divider/>
              <NavDropdown.Item eventKey="10" onClick={logOutHandler}>Logout</NavDropdown.Item>
            </NavDropdown>
            <NavItem onClick={handleShow} className="padtop sortHover">
              User Guide
            </NavItem>
            <NavItem className="padtop">
              Company: {search}
            </NavItem>
            </Nav>
            </Navbar.Collapse>
          </Navbar>
      ))}
          {/* Routes */}
          <Routes>
            {/* Conditional Routing Depending on if user has authentication */}
            <Route path="/best-software" element={!isLogged ? <Login/> : <Navigate to='/home'/>}/>
            <Route path="/forgot-password" element={<ForgotPassword/>} />
            <Route path="/home" element={isLogged ? <Home/> : <Navigate to='/redirect'/>}/>
            <Route path="/mamsearch" element ={isLogged ? <MamSearch/> : <Navigate to='/redirect'/>}/>
            <Route path="/sellers" element ={isLogged ? <Sellers/> : <Navigate to='/redirect'/>}/>
            <Route path="/seller-search-form" element ={isLogged ? <SellerSearchForm/> : <Navigate to='/redirect'/>}/>
            {/* <Route path="/clients" element ={isLogged ? <Clients/> : <Navigate to='/redirect'/>}/> */}
            <Route path="/change-password" element ={isLogged ? <ChangePassword/> : <Navigate to='/redirect'/>}/>
            <Route path="/seller-details/:SellerID" element ={isLogged ? <SellersDetails/> : <Navigate to='/redirect'/>}/>
            <Route path="/reset-password/:id/:token" element ={<ResetPassword/>}/>
            <Route path="/transport" element ={isLogged ?<Transport/>: <Navigate to='/redirect'/>}/>
            <Route path="/sources-of-finance" element ={isLogged ?<SourcesOfFinance/>: <Navigate to='/redirect'/>}/>
            <Route path="/marine-insurers" element ={isLogged ?<MarineInsurers/>: <Navigate to='/redirect'/>}/>
            <Route path="/marine-surveyors" element ={isLogged ?<MarineSurveyors/>: <Navigate to='/redirect'/>}/>
            <Route path="/marinas-and-harbours" element ={isLogged ?<MarinasAndHarbours/>: <Navigate to='/redirect'/>}/>
            <Route path="/" element={<BestSoftware/>}/>
            <Route path="/best-software-subscription" element={<BestSoftwareSub/>}/>
            <Route path="/best-software-download" element={<BestSoftwareDownload/>}/>
            <Route path="/redirect" element={<Navigate to="/best-software"/>}/>
            <Route path="*" element={<NotFound/>}/>
          </Routes>
          <div className="push"/>
          </div>
            <Footer/>
      </BrowserRouter>
  );
}

export default App;
