// Home page on login (subject to change)
function Home() {
    return (
            <div>
                <h1 className='center margin'>Welcome To the BEST web interface!</h1>
                <p className='center margin'>All services are accessible through the navbar above. If you need any help with understanding the features, see the user guide for more info.</p>
                <p className="center margin">Side Note! if you havent checked out our boat simulation software, come take a look if you get curious! <a href="https://store.steampowered.com/app/1927400/Boating_Simulator_2022/" target="_blank">Boat Simulator</a></p>
            </div>
    )
}

export default Home;