import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';
import { getData, searchFormFetch } from "../utils/utils";
import {debounce} from 'lodash';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { NavLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { Collapse, Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Button as MuiButton} from '@mui/material';
import { Alert } from 'react-bootstrap';
import LinearProgress from '@mui/material/LinearProgress';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

// React Virtualized for optimization
const LISTBOX_PADDING = 8; // px

    function renderRow(props) {
        const { data, index, style } = props;
        const dataSet = data[index];
        const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING,
        };
    
        if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
            {dataSet.group}
            </ListSubheader>
        );
        }
    
        return (
        <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
            {dataSet[1]}
        </Typography>
        );
    }

    const OuterElementContext = React.createContext({});

    const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
    });

    function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
    }

    // Adapter for react-window
    const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
        const { children, ...other } = props;
        const itemData = [];
        children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
        });
    
        const theme = useTheme();
        const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
        });
    
        const itemCount = itemData.length;
        const itemSize = smUp ? 36 : 48;
    
        const getChildSize = (child) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }
    
        return itemSize;
        };
    
        const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
        };
    
        const gridRef = useResetCache(itemCount);
    
        return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
            <VariableSizeList
                itemData={itemData}
                height={getHeight() + 2 * LISTBOX_PADDING}
                width="100%"
                ref={gridRef}
                outerElementType={OuterElementType}
                innerElementType="ul"
                itemSize={(index) => getChildSize(itemData[index])}
                overscanCount={5}
                itemCount={itemCount}
            >
                {renderRow}
            </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
        );
    });
    
    ListboxComponent.propTypes = {
        children: PropTypes.node,
    };
    
    function random(length) {
        const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
    
        for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
    
        return result;
    }
    
    const StyledPopper = styled(Popper)({
        [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
        },
    });
    // React Virtualized for optimization

    // For Overflowing table text
    function isOverflown(element) {
        return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
        );
    }
    
    const GridCellExpand = React.memo(function GridCellExpand(props) {
        const { width, value } = props;
        const wrapper = React.useRef(null);
        const cellDiv = React.useRef(null);
        const cellValue = React.useRef(null);
        const [anchorEl, setAnchorEl] = React.useState(null);
        const [showFullCell, setShowFullCell] = React.useState(false);
        const [showPopper, setShowPopper] = React.useState(false);
    
        const handleMouseEnter = () => {
        const isCurrentlyOverflown = isOverflown(cellValue.current);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
        };
    
        const handleMouseLeave = () => {
        setShowFullCell(false);
        };
    
        React.useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }
    
        function handleKeyDown(nativeEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
            setShowFullCell(false);
            }
        }
    
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        }, [setShowFullCell, showFullCell]);
    
        return (
        <Box
            ref={wrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
            alignItems: 'center',
            lineHeight: '24px',
            width: 1,
            height: 1,
            position: 'relative',
            display: 'flex',
            }}
        >
            <Box
            ref={cellDiv}
            sx={{
                height: 1,
                width,
                display: 'block',
                position: 'absolute',
                top: 0,
            }}
            />
            <Box
            ref={cellValue}
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
            {value}
            </Box>
            {showPopper && (
            <Popper
                open={showFullCell && anchorEl !== null}
                anchorEl={anchorEl}
                style={{ width, marginLeft: -17 }}
            >
                <Paper
                elevation={1}
                style={{ minHeight: wrapper.current.offsetHeight - 3 }}
                >
                <Typography variant="body2" style={{ padding: 8 }}>
                    {value}
                </Typography>
                </Paper>
            </Popper>
            )}
        </Box>
        );
    });
    
    GridCellExpand.propTypes = {
        value: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
    };
    
    function renderCellExpand(params) {
        return (
        <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
        );
    }
    
    renderCellExpand.propTypes = {
        /**
         * The column of the row that the current cell belongs to.
         */
        colDef: PropTypes.object.isRequired,
        /**
         * The cell value, but if the column has valueGetter, use getValue.
         */
        value: PropTypes.string,
    };
    
    const columns = [
        { field: 'col1', headerName: 'Column 1', width: 80, renderCell: renderCellExpand },
        {
        field: 'col2',
        headerName: 'Column 2',
        width: 100,
        renderCell: renderCellExpand,
        },
        {
        field: 'col3',
        headerName: 'Column 3',
        width: 150,
        renderCell: renderCellExpand,
        },
    ];
// For Overflowing table text

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

function SellerSearchForm(){
    const [boatLocation,setBoatLocation] = React.useState([]);
    const [boatType,setBoatType] = React.useState([]);
    const [sellersType,setSellersType] = React.useState([]);
    const [boatMakes,setBoatMakes] = React.useState([]);
    const [search, setSearch] = React.useState([{"SellersID":""}]);
    const [continent,setContinent] = React.useState("");
    const [country,setCountry] = React.useState("");
    const [region,setRegion] = React.useState("");
    const [boatMake,setBoatMake] = React.useState("");
    const [subType,setSubType] = React.useState("");
    const [mainType,setMainType] = React.useState("");
    const [lowVal,setLowVal] = React.useState(0);
    const [highVal,setHighVal] = React.useState(2147483646);
    const [boatNum,setBoatNum] = React.useState(0);
    const [typeOfSeller,setTypeOfSeller] = React.useState("");
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [shower, setShower] = React.useState(false);
    const [boatMakeWhere,setBoatMakeWhere] = React.useState([]);
    const [boatTypeWhere,setBoatTypeWhere] = React.useState([]);
    const [boatLocationWhere,setBoatLocationWhere] = React.useState([]);
    const [sellerTypeWhere,setSellerTypeWhere] = React.useState([]);
    const [sellerOnlyWhere,setSellerOnlyWhere] = React.useState([])
    const handleCloser = () => setShower(false);
    const handleShower = () => setShower(true);
    const [num,setNum] = React.useState(1); 
    const [open, setOpen] = React.useState(true);
    const [showAlert, setShowAlert] = React.useState(false);
    const handleCloseAlert = () => setShowAlert(false);
    const handleShowAlert = () => setShowAlert(true);
    const [spinner,setSpinner] = React.useState(false)
    if (boatNum === ""){
        setBoatNum(0)
    }
    if (lowVal === ""){
        setLowVal(0)
    }
    if (highVal === ""){
        setHighVal(2147483646)
    }
    const authorizeToken = localStorage.getItem('MyToken');
    // Handler for presenting data to front end
    const searchHandler = async () => {
            try {
                setSpinner(true)
                if(continent === "" || country === ""){
                    handleShow()
                    setSpinner(false)
                    return
                }
                const res = await searchFormFetch(`${process.env.REACT_APP_REST_API}seller-search-form-where`,authorizeToken,'%' + subType,boatNum,lowVal,highVal,'%' + typeOfSeller,'%' + continent,'%' + country,'%' + boatMake)
                setSearch(res)
                setSpinner(false)
            } catch (error) {
                console.log(error)
            }
        } 
    const BoatLocationHandler = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}boat-location-join`,authorizeToken)
        setBoatLocation(res)
    }

    const BoatTypeHandler = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}boat-type-join`,authorizeToken)
        setBoatType(res)
    }

    const sellerTypeHandler = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}seller-type-join`,authorizeToken)
        setSellersType(res)
    }
    const BoatMakeHandler = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}boat-make-join`,authorizeToken)
        setBoatMakes(res)
    }
// 
    const BoatMakeHandlerWhere = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}boat-make-join-where/${num}`,authorizeToken)
        setBoatMakeWhere(res)
    }
    const BoatTypeHandlerWhere = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}boat-type-join-where/${num}`,authorizeToken)
        setBoatTypeWhere(res)
    }
    const BoatLocationHandlerWhere = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}boat-location-join-where/${num}`,authorizeToken)
        setBoatLocationWhere(res)
    }
    const sellerTypeHandlerWhere = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}seller-type-join-where/${num}`,authorizeToken)
        setSellerTypeWhere(res)
    }
    const sellerOnlyHandlerWhere = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}sellers-only-where/${num}`,authorizeToken)
        setSellerOnlyWhere(res)
    }

    React.useEffect(() => {
        BoatLocationHandler()
        BoatTypeHandler()
        sellerTypeHandler()
        BoatMakeHandler()
    },[])
    React.useEffect(() => {
        BoatMakeHandlerWhere()
        BoatTypeHandlerWhere()
        BoatLocationHandlerWhere()
        sellerTypeHandlerWhere()
        sellerOnlyHandlerWhere()
    },[num])
    React.useEffect(() => {
        if(search.length === 0){
            handleShowAlert()
        }else{
            handleCloseAlert()
        }
    },[search.length])
    // Location filter doesnt work at the moment, tolowercase error bc of null values
    const locationFilter = (rows) => {
        if(country === "" && continent === ""){
            return rows
        } else if (
            rows.CountryName.toLowerCase().includes(country.toLowerCase())
            && rows.ContinentName.toLowerCase().includes(continent.toLowerCase())){
            return rows
        }
    }
    const typeFilter = (rows) => {
        if(subType === "" && mainType === ""){
            return rows
        } else if (
            rows.SubType.toLowerCase().includes(subType.toLowerCase())
            && rows.MainType.toLowerCase().includes(mainType.toLowerCase())){
            return rows
        }
    }

    const continentHandler = (event,val) => {
        setContinent(val);
    };
    const debouncedContinentHandler = React.useMemo(
        () => debounce(continentHandler, 500)
    , []);
    const countryHandler = (event,val) => {
        setCountry(val);
    };
    const debouncedCountryHandler = React.useMemo(
        () => debounce(countryHandler, 500)
    , []);
    const regionHandler = (event,val) => {
        setRegion(val);
    };
    const debouncedRegionHandler = React.useMemo(
        () => debounce(regionHandler, 500)
    , []);
    const subTypeHandler = (event,val) => {
        setSubType(val);
    };
    const debouncedSubTypeHandler = React.useMemo(
        () => debounce(subTypeHandler, 500)
    , []);
    const mainTypeHandler = (event,val) => {
        setMainType(val);
    };
    const debouncedMainTypeHandler = React.useMemo(
        () => debounce(mainTypeHandler, 500)
    , []);
    const typeOfSellerHandler = (event,val) => {
        setTypeOfSeller(val);
    };
    const debouncedTypeOfSellerHandler = React.useMemo(
        () => debounce(typeOfSellerHandler, 500)
    , []);
    const boatMakeHandler = (event,val) => {
        setBoatMake(val);
    };
    const debouncedBoatMakeHandler = React.useMemo(
        () => debounce(boatMakeHandler, 500)
    , []);

    const columns = [
        // {field:'SellersID', headerName:'SellerID', flex:1, minWidth:60},
        {field:'CompanyName', headerName:'Company Name', flex:1, minWidth:300,renderCell: (cellValues) => {
            return <Link className='pointer' onClick={() => {handleShower();setNum(cellValues.row.SellersID)}} data-toggle="tooltip" data-placement="top" title="Click For More Info">{cellValues.row.CompanyName}</Link>;
        },},
        {field:'Email', headerName:'Email', flex:1, minWidth:300,renderCell: renderCellExpand,},
        {field:'Phone', headerName:'Phone Number', flex:1, minWidth:200,renderCell: renderCellExpand,},
        {field:'Website', headerName:'Website', flex:1, renderCell: (cellValues) => {
            return <Link href={`${cellValues.row.Website}`} target='_blank'>{cellValues.row.Website}</Link>;
        }, minWidth:250},
        {field:'NumberOfBoats', headerName:'No. Of Boats', minWidth:150,renderCell: renderCellExpand,},
        {field:'LowestValue95PerCentGBP', headerName:'LowestValue95PerCentGBP', flex:1, minWidth:250,renderCell: renderCellExpand,},
        {field:'HighestValue95PerCentGBP', headerName:'HighestValue95PerCentGBP', flex:1, minWidth:250,renderCell: renderCellExpand,},
        {field:'NotesOnSellers', headerName:'Notes', flex:1, minWidth:400,renderCell: renderCellExpand,},
    ];

    return(
        <div>
            <MuiButton onClick={() => setOpen(!open)} aria-controls="collapse" aria-expanded={open}>
                {!open ? "Show Filters" : "Close Filters"}
            </MuiButton>
            <Modal show={shower} onHide={handleCloser} scrollable>
                <Modal.Header closeButton>
                <Modal.Title><div className='center'>
                    {sellerOnlyWhere.map((row) => {
                        return <p className='largeFont'>{row.CompanyName}</p>
                    })}
                </div></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>
            <Box sx={{ flexGrow: 1 }} id="collapse">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center'>
                        <p className='underlined'>Seller Type</p>
                        {sellerTypeWhere.map((row) => {
                                return (
                                <div>
                                    <p>{row.Seller_Type}</p>
                                </div>
                                )
                            })}
                        </div>
                    </Item>
                    </Grid>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center'>
                        <p className='underlined'>Sub Types listed by the seller</p>
                            {boatTypeWhere.map((row) => {
                                return (
                                <div>
                                    <p>{row.SubType}</p>
                                </div>
                                )
                            })}
                        </div>
                    </Item>
                    </Grid>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center'>
                            <p className='underlined'>Makes the seller specialises in</p>
                            {boatMakeWhere.map((row) => {
                                return(
                                <div>
                                    <p>{row.BoatMake}</p>
                                </div>
                                )
                            })}
                        </div>
                    </Item>
                    </Grid>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center'>
                            <p className='underlined'>Locations</p>
                            {boatLocationWhere.map((row) => {
                                return (
                                <div>
                                    <p>{row.ContinentName}, {row.CountryName} {row.RegionName}</p>
                                </div>
                                )
                            })}
                        </div>
                    </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloser}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please fill in "Filter Search Locations"</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Ok
                </Button>
                </Modal.Footer>
            </Modal>
            <Collapse in={open}>
                <div>
                <Box sx={{ width: '100%' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                        <Item>
                            <h4>Filter Search Locations</h4>
                            <div className='Autocomplete'>
                                <Stack spacing={2} sx={{ width: 300 }} className='padding'>
                                    <Autocomplete
                                        id="virtualize-demo"
                                        disableListWrap
                                        freeSolo
                                        onInputChange={debouncedContinentHandler}
                                        PopperComponent={StyledPopper}
                                        ListboxComponent={ListboxComponent}
                                        options={[...new Set(boatLocation.filter(x => x.ContinentName !== null).sort((a, b) => a.ContinentName.localeCompare(b.ContinentName)).map((option) => option.ContinentName))]}
                                        // groupBy={(option) => option[0]}
                                        renderInput={(params) => <TextField {...params} label="Select Continent" />}
                                        renderOption={(props, option) => [props, option]}
                                        renderGroup={(params) => params}
                                    />
                                </Stack>
                                <Stack spacing={2} sx={{ width: 300 }} className='padding'>
                                    <Autocomplete
                                        id="virtualize-demo"
                                        disableListWrap
                                        freeSolo
                                        onInputChange={debouncedCountryHandler}
                                        PopperComponent={StyledPopper}
                                        ListboxComponent={ListboxComponent}
                                        options={[...new Set(boatLocation.filter(x => x.CountryName !== null).filter(locationFilter).sort((a, b) => a.CountryName.localeCompare(b.CountryName)).map((option) => option.CountryName))]}
                                        // groupBy={(option) => option[0]}
                                        renderInput={(params) => <TextField {...params} label="Select Country" />}
                                        renderOption={(props, option) => [props, option]}
                                        renderGroup={(params) => params}
                                    />
                                </Stack>
                                {/* <Stack spacing={2} sx={{ width: 300 }} className='padding'>
                                    <Autocomplete
                                        id="virtualize-demo"
                                        disableListWrap
                                        freeSolo
                                        onInputChange={debouncedRegionHandler}
                                        PopperComponent={StyledPopper}
                                        ListboxComponent={ListboxComponent}
                                        options={[...new Set(boatLocation.filter(x => x.RegionName !== null).sort((a, b) => a.RegionName.localeCompare(b.RegionName)).map((option) => option.RegionName))]}
                                        // groupBy={(option) => option[0]}
                                        renderInput={(params) => <TextField {...params} label="Restrict Region" />}
                                        renderOption={(props, option) => [props, option]}
                                        renderGroup={(params) => params}
                                    />
                                </Stack> */}
                            </div>
                        </Item>
                        </Grid>
                        <Grid item xs={12}>
                        <Item>
                            <h4>Range Filters</h4>
                            <div className='Autocomplete'>
                            <Stack spacing={2} sx={{ width: 300 }} className='padding'>
                            <TextField id="filled-basic" label="Lowest Price" variant="filled" type="number" onChange={(e) => setLowVal(e.target.value)} />
                            </Stack>
                            <Stack spacing={2} sx={{ width: 300 }} className='padding'>
                            <TextField id="filled-basic" label="Highest Price" variant="filled" type="number" onChange={(e) => setHighVal(e.target.value)}/>
                            </Stack>
                            <Stack spacing={2} sx={{ width: 300 }} className='padding'>
                            <TextField id="filled-basic" label="Minimum Number Of Boats" type="number" variant="filled" onChange={(e) => setBoatNum(e.target.value)} />
                            </Stack>
                            </div>
                        </Item>
                        </Grid>
                        <Grid item xs={12}>
                        <Item>
                            <h4>Filter Boat Types</h4>
                            <div className='Autocomplete'>
                                <Stack spacing={2} sx={{ width: 300 }} className='padding'>
                                    <Autocomplete
                                        id="virtualize-demo"
                                        disableListWrap
                                        freeSolo
                                        onInputChange={debouncedMainTypeHandler}
                                        PopperComponent={StyledPopper}
                                        ListboxComponent={ListboxComponent}
                                        options={[...new Set(boatType.filter(x => x.MainType !== null).filter(typeFilter).sort((a, b) => a.MainType.localeCompare(b.MainType)).map((option) => option.MainType))]}
                                        // groupBy={(option) => option[0]}
                                        renderInput={(params) => <TextField {...params} label="Restrict Main Type" />}
                                        renderOption={(props, option) => [props, option]}
                                        renderGroup={(params) => params}
                                    />
                                </Stack>
                                <Stack spacing={2} sx={{ width: 300 }} className='padding'>
                                    <Autocomplete
                                        id="virtualize-demo"
                                        disableListWrap
                                        freeSolo
                                        onInputChange={debouncedSubTypeHandler}
                                        PopperComponent={StyledPopper}
                                        ListboxComponent={ListboxComponent}
                                        options={[...new Set(boatType.filter(x => x.SubType !== null).filter(typeFilter).sort((a, b) => a.SubType.localeCompare(b.SubType)).map((option) => option.SubType))]}
                                        // groupBy={(option) => option[0]}
                                        renderInput={(params) => <TextField {...params} label="Restrict Sub Type" />}
                                        renderOption={(props, option) => [props, option]}
                                        renderGroup={(params) => params}
                                    />
                                </Stack>
                                <Stack spacing={2} sx={{ width: 300 }} className='padding'>
                                    <Autocomplete
                                        id="virtualize-demo"
                                        disableListWrap
                                        freeSolo
                                        onInputChange={debouncedBoatMakeHandler}
                                        PopperComponent={StyledPopper}
                                        ListboxComponent={ListboxComponent}
                                        options={[...new Set(boatMakes.filter(x => x.BoatMake !== null).sort((a, b) => a.BoatMake.localeCompare(b.BoatMake)).map((option) => option.BoatMake))]}
                                        // groupBy={(option) => option[0]}
                                        renderInput={(params) => <TextField {...params} label="Choose Boat Make" />}
                                        renderOption={(props, option) => [props, option]}
                                        renderGroup={(params) => params}
                                    />
                                </Stack>
                            </div>
                        </Item>
                        </Grid>
                        <Grid item xs={12}>
                        <Item>
                            <h4>Filter Seller Types</h4>
                            <div className='Autocomplete'>
                                <Stack spacing={2} sx={{ width: 300 }} className='padding'>
                                    <Autocomplete
                                        id="virtualize-demo"
                                        disableListWrap
                                        freeSolo
                                        onInputChange={debouncedTypeOfSellerHandler}
                                        PopperComponent={StyledPopper}
                                        ListboxComponent={ListboxComponent}
                                        options={[...new Set(sellersType.filter(x => x.Seller_Type !== null).sort((a, b) => a.Seller_Type.localeCompare(b.Seller_Type)).map((option) => option.Seller_Type))]}
                                        // groupBy={(option) => option[0]}
                                        renderInput={(params) => <TextField {...params} label="Seller Type" />}
                                        renderOption={(props, option) => [props, option]}
                                        renderGroup={(params) => params}
                                    />
                                </Stack>
                            </div>
                        </Item>
                        </Grid>
                    </Grid>
                </Box>
                <div className='d-grid gap-2 col-6 mx-auto padding'>
                {!spinner ? 
                <button type="button" className='btn btn-secondary btn-lg' onClick={searchHandler}>Search Sellers</button>
                :
                <Box sx={{ width: '100%'}}>
                <LinearProgress />
                </Box>
                }
                <Alert show={showAlert} variant="danger" className='center margin'>No Results</Alert>
                </div>
                </div>
                </Collapse>
                <div style={{ height: '75vh', width: '100%' }}>
                <DataGrid
                    rows={search}
                    columns={columns}
                    getRowId={e => e.SellersID}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    checkboxSelection
                    disableSelectionOnClick
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    
                    sx={{
                        '& .MuiDataGrid-cell': {
                            overflow: "hidden",
                            whiteSpace: "break-spaces",
                            fontSize:13,
                            fontWeight:600
                        },
                        '& .MuiDataGrid-columnHeader':{
                            fontSize:14,
                            fontWeight:600
                        }
                    }}
                    />
            </div>
        </div>
    )
}

export default SellerSearchForm