import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import BestSoftwareMenu from './BestSoftwareMenu';
import Divider from '@mui/material/Divider';
function BestSoftwareSub() {
    return (
        <div>
            <h1 className='center bluebg heading'>Best Software Subscription</h1>
            <BestSoftwareMenu />
            <Divider/>
            <h3 className='center bestWidth BestSoftwareHeading'>Start your 30 day free trial today</h3>
            <p className="center bestWidth BestSoftwarefont">The Boating Enterprise Search Tool (BEST) is an easy to use, powerful piece of software focused on helping boat brokers do more business, earn additional revenue, and search the whole market to fulfil their client’s boating requirements – more effectively than any other software on the market.</p>
            <p className="center bestWidth BestSoftwarefont">We developed the software inhouse to enable us to serve our clients better and find their ideal boat. We have developed it further for boat brokers and we’d love you to try it and see how it can enhance your business.</p>
            <p className="center bestWidth BestSoftwarefont">Try BEST for free with no risk or obligation. Start your free trial today – simply chose which option you’d prefer below. Both Standard and Superuser options are available to trial.</p>
            <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
                fill
            >
                <Tab eventKey="home" title="Monthly">
                    <Container>
                        <Row>
                            <Col>
                            <div className='bestPurchaseWindow center margin'>
                                <h2><b>Standard Plan</b></h2>
                                <h2>£59.95/Month + VAT</h2>
                                <p className='margin'>Access to sellers, sellers search form & search seller by name <CheckIcon/></p>
                                <p className='margin'>Make & Model Search <CheckIcon/></p>
                                <p className='margin'>Internet Access Required <CheckIcon/></p>
                                <p className='margin'><b>Note: The Standard plan is just for the Desktop Edition that requires a Windows computer; and does not include the Web Edition.</b></p>
                                <div className='center margin'><Button variant="contained" href='https://subscriptions.zoho.eu/subscribe/89b088ec710d8de35e3b5fb05777ed686a2bba0f6b053217651bdda4a1c7d344/standard' target='about_blank'>Buy Now!</Button></div>
                                <p className='center margin'>30 day free trial</p>
                            </div>
                            </Col>
                            <Col>
                            <div className='bestPurchaseWindow center margin'>
                                <h2><b>Superuser Plan</b></h2>
                                <h2>£79.95/Month + VAT</h2>
                                <p className='margin'><b>All the standard features Included PLUS:</b></p>
                                <p className='margin'>Marine Insurers <CheckIcon/></p>
                                <p className='margin'>Marine Surveyors <CheckIcon/></p>
                                <p className='margin'>Access to Transport for boats <CheckIcon/></p>
                                <p className='margin'>Access to Sources of Finance <CheckIcon/></p>
                                <p className='margin'>Web edition of BEST for use on almost any device <CheckIcon/></p>
                                <div className='center margin'><Button variant="contained" href='https://subscriptions.zoho.eu/subscribe/89b088ec710d8de35e3b5fb05777ed686a2bba0f6b053217651bdda4a1c7d344/superuser' target='about_blank'>Buy Now!</Button></div>
                                <p className='center margin'>30 day free trial</p>
                            </div>
                            </Col>
                        </Row>
                    </Container>
                </Tab>
                <Tab eventKey="profile" title="Annual">
                <Container>
                        <Row>
                            <Col>
                            <div className='bestPurchaseWindow center'>
                                <h2><b>Standard Plan</b></h2>
                                <h2>£499.50/Year + VAT</h2>
                                <p className='margin'>Access to sellers, sellers search form & search seller by name <CheckIcon/></p>
                                <p className='margin'>Make & Model Search <CheckIcon/></p>
                                <p className='margin'>Internet Access Required <CheckIcon/></p>
                                <p className='margin'><b>Note: The Standard plan is just for the Desktop Edition that requires a Windows computer; and does not include the Web Edition.</b></p>
                                <div className='center margin'><Button variant="contained" href='https://subscriptions.zoho.eu/subscribe/89b088ec710d8de35e3b5fb05777ed686a2bba0f6b053217651bdda4a1c7d344/standard-annual' target='about_blank'>Buy Now!</Button></div>
                                <p className='center margin'>30 day free trial</p>
                            </div>
                            </Col>
                            <Col>
                            <div className='bestPurchaseWindow center'>
                                <h2><b>Superuser Plan</b></h2>
                                <h2>£799.50/Year + VAT</h2>
                                <p className='margin'><b>All the standard features Included PLUS:</b></p>
                                <p className='margin'>Marine Insurers <CheckIcon/></p>
                                <p className='margin'>Marine Surveyors <CheckIcon/></p>
                                <p className='margin'>Access to Transport for boats <CheckIcon/></p>
                                <p className='margin'>Access to Sources of Finance <CheckIcon/></p>
                                <p className='margin'>Web edition of BEST for use on almost any device <CheckIcon/></p>
                                <div className='center margin'><Button variant="contained" href='https://subscriptions.zoho.eu/subscribe/89b088ec710d8de35e3b5fb05777ed686a2bba0f6b053217651bdda4a1c7d344/superannual' target='about_blank'>Buy Now!</Button></div>
                                <p className='center margin'>30 day free trial</p>
                            </div>
                            </Col>
                        </Row>
                    </Container>
                </Tab>
            </Tabs>
        </div>
    )
}

export default BestSoftwareSub;