import 'bootstrap/dist/css/bootstrap.css';
import { useState } from 'react';
import { getData } from '../utils/utils';
import * as React from 'react';
import PropTypes from 'prop-types';

// Material Ui Imports
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { Link } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

        // For Overflowing table text
        function isOverflown(element) {
            return (
            element.scrollHeight > element.clientHeight ||
            element.scrollWidth > element.clientWidth
            );
        }
        
        const GridCellExpand = React.memo(function GridCellExpand(props) {
            const { width, value } = props;
            const wrapper = React.useRef(null);
            const cellDiv = React.useRef(null);
            const cellValue = React.useRef(null);
            const [anchorEl, setAnchorEl] = React.useState(null);
            const [showFullCell, setShowFullCell] = React.useState(false);
            const [showPopper, setShowPopper] = React.useState(false);
        
            const handleMouseEnter = () => {
            const isCurrentlyOverflown = isOverflown(cellValue.current);
            setShowPopper(isCurrentlyOverflown);
            setAnchorEl(cellDiv.current);
            setShowFullCell(true);
            };
        
            const handleMouseLeave = () => {
            setShowFullCell(false);
            };
        
            React.useEffect(() => {
            if (!showFullCell) {
                return undefined;
            }
        
            function handleKeyDown(nativeEvent) {
                // IE11, Edge (prior to using Bink?) use 'Esc'
                if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
                }
            }
        
            document.addEventListener('keydown', handleKeyDown);
        
            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
            }, [setShowFullCell, showFullCell]);
        
            return (
            <Box
                ref={wrapper}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                alignItems: 'center',
                lineHeight: '24px',
                width: 1,
                height: 1,
                position: 'relative',
                display: 'flex',
                }}
            >
                <Box
                ref={cellDiv}
                sx={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
                />
                <Box
                ref={cellValue}
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                {value}
                </Box>
                {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17 }}
                >
                    <Paper
                    elevation={1}
                    style={{ minHeight: wrapper.current.offsetHeight - 3 }}
                    >
                    <Typography variant="body2" style={{ padding: 8 }}>
                        {value}
                    </Typography>
                    </Paper>
                </Popper>
                )}
            </Box>
            );
        });
        
        GridCellExpand.propTypes = {
            value: PropTypes.string.isRequired,
            width: PropTypes.number.isRequired,
        };
        
        function renderCellExpand(params) {
            return (
            <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
            );
        }
        
        renderCellExpand.propTypes = {
            /**
             * The column of the row that the current cell belongs to.
             */
            colDef: PropTypes.object.isRequired,
            /**
             * The cell value, but if the column has valueGetter, use getValue.
             */
            value: PropTypes.string,
        };
        
        const columns = [
            { field: 'col1', headerName: 'Column 1', width: 80, renderCell: renderCellExpand },
            {
            field: 'col2',
            headerName: 'Column 2',
            width: 100,
            renderCell: renderCellExpand,
            },
            {
            field: 'col3',
            headerName: 'Column 3',
            width: 150,
            renderCell: renderCellExpand,
            },
        ];
    // For Overflowing table text

function Transport() {
    const [search, setSearch] = useState([]);
    const [loading,setLoading] = useState(false);
    const [trailer,setTrailer] = useState(false);
    const [international,setInternational] = useState(false);
    const [HIAB,setHIAB] = useState(false);
    const [inland,setInland] = useState(false);
    const [skipper,setSkipper] = useState(false);
    const [superYachts,setSuperYachts] = useState(false);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        Trailer: false,
        International: false,
        HIAB: false,
        SuperYachts: false,
        InlandWaterWay: false,
        CanSkipper: false
    });

    const trailerHandler = () => {
        if(trailer === false){
            setTrailer(true)
        } else if (trailer === true){
            setTrailer(false)
        }
    }
    const InternationalHandler = () => {
        if(international === false){
            setInternational(true)
        } else if(international === true){
            setInternational(false)
        }
    }
    const HIABHandler = () => {
        if(HIAB === false){
            setHIAB(true)
        } else if(HIAB === true){
            setHIAB(false)
        }
    }
    const inlandHandler = () => {
        if(inland === false){
            setInland(true)
        } else if(inland === true){
            setInland(false)
        }
    }
    const skipperHandler = () => {
        if(skipper === false){
            setSkipper(true)
        } else if(skipper === true){
            setSkipper(false)
        }
    }
    const superYachtsHandler = () => {
        if(superYachts === false){
            setSuperYachts(true)
        } else if(superYachts === true){
            setSuperYachts(false)
        }
    }

    // Used for validated requests (so unauthorised users cant steal data)
    const authorizeToken = localStorage.getItem('MyToken')

    const searchHandler = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}transport`, authorizeToken)
        setSearch(res)
    }
    React.useEffect(() => {
        searchHandler()
    },[])

    if( !search.length > 0 || loading){
        return (
            <div className="middlePage">
                <Box sx={{ display: 'flex' }}>
                <CircularProgress />
                </Box>
            </div>
        )
    }
    const columns = [
        {field:'CompanyName', headerName:'Company Name', flex:1, minWidth:300,renderCell: renderCellExpand,},
        {field:'Email', headerName:'Email', flex:1, minWidth:300,renderCell: renderCellExpand,},
        {field:'Website', headerName:'Website', flex:1, renderCell: (cellValues) => {
            return <Link href={`${cellValues.row.Website}`} target='_blank'>{cellValues.row.Website}</Link>;
        }, minWidth:250},
        {field:'ContactName', headerName:'Name', flex:1, minWidth:100,renderCell: renderCellExpand,},
        {field:'PhoneOffice', headerName:'Office Number', flex:1, minWidth:200,renderCell: renderCellExpand,},
        {field:'PhoneMobile', headerName:'Mobile Number', flex:1, minWidth:200,renderCell: renderCellExpand,},
        {field:'Town', headerName:'Town', flex:1, minWidth:150,renderCell: renderCellExpand,},
        {field:'County', headerName:'County', flex:1, minWidth:100,renderCell: renderCellExpand,},    
        {field:'CountryName', headerName:'Country', flex:1, minWidth:100,renderCell: renderCellExpand,},
        {field:'AddressLine1', headerName:'AddressLine1', flex:1, minWidth:200,renderCell: renderCellExpand,},
        {field:'AddressLine2', headerName:'AddressLine2', flex:1, minWidth:200,renderCell: renderCellExpand,},
        {field:'AddressLine3', headerName:'AddressLine3', flex:1, minWidth:200,renderCell: renderCellExpand,},
        {field:'PostCodeZip', headerName:'PostCode', flex:1, minWidth:100,renderCell: renderCellExpand,},
        {field:'NotesOnTransportCompany', headerName:'Notes', flex:1, minWidth:400,renderCell: renderCellExpand,},
        {field:'Trailer', headerName:'Trailer', flex:1,renderCell: renderCellExpand,},
        {field:'International', headerName:'International', flex:1,renderCell: renderCellExpand,},
        {field:'HIAB', headerName:'HIAB', flex:1,renderCell: renderCellExpand,},
        {field:'SuperYachts', headerName:'SuperYachts', flex:1,renderCell: renderCellExpand,},
        {field:'InlandWaterWay', headerName:'InlandWaterWay', flex:1,renderCell: renderCellExpand,},
        {field:'CanSkipper', headerName:'CanSkipper', flex:1,renderCell: renderCellExpand,},
    ]

    const filter = (rows) => {
        if(trailer === false && international === false && HIAB === false && superYachts === false && inland === false && skipper === false){
            return rows
        } else if (
            rows.Trailer === trailer &&
            rows.International === international &&
            rows.HIAB === HIAB &&
            rows.SuperYachts === superYachts &&
            rows.InlandWaterWay === inland &&
            rows.CanSkipper === skipper){
            return rows
        }
    }

    return (
            <div>
                <h4 className='center margin'>Transport</h4>
                <h5 className='center margin'>Filter by Service Type</h5>
                <div className='Autocomplete'>
                    <FormControlLabel control={<Checkbox onClick={trailerHandler} />}  label="Trailer" />
                    <FormControlLabel control={<Checkbox onClick={InternationalHandler}/>}  label="International" />
                    <FormControlLabel control={<Checkbox onClick={HIABHandler}/>}  label="HIAB" />
                    <FormControlLabel control={<Checkbox onClick={inlandHandler}/>}  label="Inland w/way" />
                    <FormControlLabel control={<Checkbox onClick={skipperHandler} />}  label="Can Skipper" />
                    <FormControlLabel control={<Checkbox onClick={superYachtsHandler}/>}  label="SuperYachts" />
                </div>
            <div style={{ height: '70vh', width: '100%' }}>
                <DataGrid
                    rows={search.filter(filter)}
                    columns={columns}
                    getRowId={e => e.TransportID}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    checkboxSelection
                    disableSelectionOnClick
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    loading={loading}
                    columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                        setColumnVisibilityModel(newModel)
                        }
                    sx={{
                        '& .MuiDataGrid-cell': {
                            overflow: "hidden",
                            whiteSpace: "break-spaces",
                            fontSize:13,
                            fontWeight:600
                        },
                        '& .MuiDataGrid-columnHeader':{
                            fontSize:14,
                            fontWeight:600
                        }
                    }}
                    />
            </div>
        </div>
    )
}

export default Transport;