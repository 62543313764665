import { useState, useEffect } from "react";
import { tokenCheck, updateFetch } from "../utils/utils";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Alert } from "react-bootstrap";

function ChangePassword() {
    const [user,setUser] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordType,setPasswordType] = useState("password");
    const [confPasswordType,setConfPasswordType] = useState("password");
    // Alert Handlers
    const [show,setShow] = useState(false)
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [showSuccess,setShowSuccess] = useState(false)
    const handleShowSuccess = () => setShowSuccess(true);
    const handleCloseSuccess = () => setShowSuccess(false);
    const [showPassLength,setShowPassLength] = useState(false)
    const handleShowPassLength = () => setShowPassLength(true);
    const handleClosePassLength = () => setShowPassLength(false);

    // Token Validation
    useEffect(() => {
        tokenCheck(localStorage.getItem("MyToken"), setUser);
    }, []);

    // Show and hide password handlers
    const passShowHandler = () => {
        if(passwordType === "text"){
            setPasswordType("password")
        }else{
            setPasswordType("text")
        }
    };
    const confPassShowHandler = () => {
        if(confPasswordType === "text"){
            setConfPasswordType("password")
        }else{
            setConfPasswordType("text")
        }
    };

    // Handles Fetch request for changing password
    const updateHandler = async (e) => {
        e.preventDefault();
        try {
            if(password === confirmPassword){
                const fetcher = await updateFetch(user,password,localStorage.getItem('MyToken'))
                if(fetcher.message === "Password length must be longer than 8 characters"){
                    handleClose()
                    handleCloseSuccess()
                    handleShowPassLength()
                    setPassword("")
                    setConfirmPassword("")
                }else{
                    handleClose()
                    handleClosePassLength()
                    handleShowSuccess()
                    setPassword("")
                    setConfirmPassword("")
                }
            }else{
                handleClosePassLength()
                handleCloseSuccess()
                handleShow()
                setPassword("")
                setConfirmPassword("")
            }
        } catch (error) {
            console.log(error);
            alert("Error");
        }
    }

    return(
        <div className="wrapper">
            <form className="p-3 mt-3" onSubmit={updateHandler}>
                <div className="form-field d-flex align-items-center"><input type={passwordType} placeholder="Enter New Password" value={password} onChange={e => setPassword(e.target.value)} /><div className="pointer">{passwordType === "password" ? <VisibilityOffIcon className="visIconStyle" onClick={passShowHandler}/> : <VisibilityIcon className="visIconStyle" onClick={passShowHandler}/>}</div></div>
                <div className="form-field d-flex align-items-center"><input type={confPasswordType} placeholder="Confirm Password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} /><div className="pointer">{confPasswordType === "password" ? <VisibilityOffIcon className="visIconStyle" onClick={confPassShowHandler}/> : <VisibilityIcon className="visIconStyle" onClick={confPassShowHandler}/>}</div></div>
                <button className="btn" type="submit">
                Update Password
                </button>
            </form>
            <Alert show={showSuccess} variant="success" className='center margin'>Successfully changed password</Alert>
            <Alert show={show} variant="danger" className='center margin'>Error: Passwords do not match</Alert>
            <Alert show={showPassLength} variant="danger" className='center margin'>Password must be longer than 8 characters</Alert>
        </div>
    )
}

export default ChangePassword;