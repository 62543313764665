import BestSoftwareMenu from './BestSoftwareMenu';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Divider from '@mui/material/Divider';
function BestSoftware() {
    return (
        <div>
            {/* <img width="220" height="67"  align="left" src="GoEarthLogo.png"/> */}
            <h1 className='center bluebg heading'> Boating Enterprise Search Tool - BEST</h1>
            <BestSoftwareMenu />
            <Divider/>
            <h2 className='center bestWidth BestSoftwareHeading'>Boat brokers - do more business with BEST</h2>
            <p className="center bestWidth BestSoftwarefont">BEST is an innovative piece of software that was developed by Go Earth, as a way for brokers to do more business, earn additional revenue, and search the whole market to fulfil their client’s boating requirements – more effectively than any other software on the market.</p>
            <Divider/>
            <Container fluid>
                <Row>
                    <Col>
                        <div className='responsive-youtube margin'>
                            <iframe src="https://www.youtube.com/embed/FWtag-1rJqA" title="What is 'BEST' Software?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </Col>
                    <Col>
                        <div className='margin'>
                            <h2 className='BestSoftwareHeading'>Try BEST for free!</h2>
                            <p className=" BestSoftwarefont"> All first time users will receive a 30 day free trial. Please See <a href='/best-software-subscription'>here</a> to subscribe</p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Divider/>
        </div>
    )
}

export default BestSoftware;