import styled from 'styled-components';

export const Box = styled.div`
padding: 40px 60px;
background: #18a79d;
bottom: 0;
width: 100%;


@media (max-width: 1000px) {
	padding: 35x 30px;
}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
    width: 100%;
	margin: 0 auto;
`

export const Column = styled.div`
display: flex;
flex-direction: column;
text-align: left;
margin-left: 60px;
`;

export const Row = styled.div`
display: flex;
flex-direction: row;
justify-content: space-evenly;
flex-wrap: wrap ;

@media (max-width: 1000px) {
	
}
`;

export const FooterLink = styled.a`
color: #fff;
margin-bottom: 20px;
font-size: 16px;
text-decoration: none;

&:hover {
	color: black;
	transition: 200ms ease-in;
}
`;

export const Heading = styled.p`
font-size: 24px;
color: #fff;
margin-bottom: 40px;
font-weight: bold;
`;
