import { Link } from "react-router-dom";
import React, { useState } from "react";
import { forgotFetch } from "../utils/utils";
import { Alert } from "react-bootstrap";
import BestSoftwareMenu from "./BestSoftwareMenu";
import { Divider } from "@mui/material";

function ForgotPassword() {
    const [email,setEmail] = useState("")
    const [emailSent,setEmailSent] = useState(false)
    
    // Alert handling
    const [show,setShow] = useState(false)
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    // Form Submit Handler
    const submitHandler = async (e) => {
        e.preventDefault()
        try {
            const returnValue = await forgotFetch(email);
            console.log(returnValue.message)
            if(returnValue.message === "Success"){
                setEmailSent(true)
            }else{
                handleShow()
            }
        } catch (error) {
            console.log(error);
            handleShow();
        }   
    }
    return (
            <div>
                <h1 className='center bluebg heading'>Best Web Version</h1>
                <BestSoftwareMenu />
                <Divider/>
                <div className="wrapper">
                    <div className="logo"><img src="https://yt3.ggpht.com/HWfTl3On_as01ml97hhj8YKSo3P7DQ_HWffmgEZ_QmROI_bSNMeOsCUL4qBpLSu6uqdj81sAgw=s900-c-k-c0x00ffffff-no-rj" alt=""/></div>
                    {!emailSent ? (
                    <div>
                    <h5 className="text-center mt-4 name">Enter your email below and we will send you a reset link.</h5>
                    <form className="p-3 mt-3" >
                        <div className="form-field d-flex align-items-center">
                            <input type="text" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                        <button className="btn" type="submit" onClick={submitHandler}>
                        Send Reset Link
                        </button>
                    </form>
                    </div>
                    ):(
                        <div>
                            <h5 className="text-center mt-4 name">Email sent! Check your spam if it is not in your inbox. The link will expire in 15 minutes.</h5>
                        </div>
                    )}
                    <Link to="/best-software" className="flexCenter">Back to Login</Link>
                    <Alert show={show} variant="danger" className='center margin'>Email Does not exist</Alert>
                </div>
            </div>
    )
}

export default ForgotPassword;