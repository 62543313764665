// Only used for Best software marketing side of the website, not main funcionality
import * as React from 'react';
import Nav from 'react-bootstrap/Nav';
import { Button } from '@mui/material';
function BestSoftwareMenu() {
    return (
        <Nav
            className="justify-content-around greenbg"
        >
            <Nav.Item>
                <Nav.Link href="/"><Button variant="text"><p className='whiteFont'>Best Software</p></Button></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href='/best-software-subscription'><Button variant="text"><p className='whiteFont'>Best Software Subscription</p></Button></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="https://www.dropbox.com/s/20j7m2401kc4fga/BEST%20Brochure%20-%20Go%20Earth.pdf?dl=1"><Button variant="text"><p className='whiteFont'>Download Our Brochure</p></Button></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/best-software">
                <Button variant="text"><p className='whiteFont'>Best Web Version</p></Button>
                </Nav.Link>
            </Nav.Item>
        </Nav>
    )
}

export default BestSoftwareMenu;