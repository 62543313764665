import * as React from 'react'
import { Link, useParams } from "react-router-dom";
import { getData } from "../utils/utils";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material';

function SellersDetails(){
    const [boatMake,setBoatMake] = React.useState([]);
    const [boatType,setBoatType] = React.useState([]);
    const [boatLocation,setBoatLocation] = React.useState([]);
    const [sellerType,setSellerType] = React.useState([]);
    const [isLoading,setIsLoading] = React.useState(false)
    const [sellerOnly,setSellerOnly] = React.useState([])

    // Params for URL to be unique for each record of data
    const params = useParams();
    const num = parseInt(params.SellerID)
    // Token needed for authorized requests
    const authorizeToken = localStorage.getItem('MyToken')
    // Data Handlers
    const BoatMakeHandler = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}boat-make-join-where/${num}`,authorizeToken)
        setBoatMake(res)
    }
    const BoatTypeHandler = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}boat-type-join-where/${num}`,authorizeToken)
        setBoatType(res)
    }
    const BoatLocationHandler = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}boat-location-join-where/${num}`,authorizeToken)
        setBoatLocation(res)
    }
    const sellerTypeHandler = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}seller-type-join-where/${num}`,authorizeToken)
        setSellerType(res)
    }
    const sellerOnlyHandler = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}sellers-only-where/${num}`,authorizeToken)
        setSellerOnly(res)
    }
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));
    React.useEffect(() => {
        BoatMakeHandler()
        BoatTypeHandler()
        BoatLocationHandler()
        sellerTypeHandler()
        sellerOnlyHandler()
    },[])
    
    // Conditional Loading
    // if(!boatMake.length || !boatType.length || !boatLocation.length || !sellerType.length > 0 || !sellerOnly.length > 0 || isLoading){
    //     return (
    //         <div className="middlePage">
    //             <Box sx={{ display: 'flex' }}>
    //             <CircularProgress />
    //             </Box>
    //         </div>
    //     )
    // };
    return(
        <div>
            {/* <Link className="link" to='/sellers'><ArrowBackIcon sx={{ fontSize: 40 }}/></Link> */}
            <div className='center'>
                    {sellerOnly.filter(e => e.SellersID === num).map((row) => {
                        return <p className='largeFont'>{row.CompanyName}</p>
                    })}
                </div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center'>
                        <p className='underlined'>Seller Type</p>
                        {sellerType.filter(e => e.SellersID === num).map((row) => {
                                return (
                                <div>
                                    <p>{row.Seller_Type}</p>
                                </div>
                                )
                            })}
                        </div>
                    </Item>
                    </Grid>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center'>
                        <p className='underlined'>Sub Types listed by the seller</p>
                            {boatType.filter(e => e.SellersID === num).map((row) => {
                                return (
                                <div>
                                    <p>{row.SubType}</p>
                                </div>
                                )
                            })}
                        </div>
                    </Item>
                    </Grid>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center'>
                            <p className='underlined'>Makes the seller specialises in</p>
                            {boatMake.filter(e => e.SellersID === num).map((row) => {
                                return(
                                <div>
                                    <p>{row.BoatMake}</p>
                                </div>
                                )
                            })}
                        </div>
                    </Item>
                    </Grid>
                    <Grid item xs={12}>
                    <Item>
                        <div className='container-lg center'>
                            <p className='underlined'>Locations</p>
                            {boatLocation.filter(e => e.SellersID === num).map((row) => {
                                return (
                                <div>
                                    <p>{row.ContinentName}, {row.CountryName} {row.RegionName}</p>
                                </div>
                                )
                            })}
                        </div>
                    </Item>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default SellersDetails;