import React from "react";
import {
Box,
Container,
Row,
Column,
FooterLink,
Heading,
} from "./FooterStyles";
import Divider from '@mui/material/Divider';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
const Footer = () => {
return (
	<Box>
	<Container>
		<Row>
		{/* <Column>
			<Heading>Services</Heading>
			<FooterLink href="https://www.boatsearch.earth/boat-search/" target='about_blank'>Boat Search</FooterLink>
			<FooterLink href="https://www.boatsearch.earth/boat-survey/" target='about_blank'>Boat Survey</FooterLink>
			<FooterLink href="https://www.boatsearch.earth/boat-transporting/" target='about_blank'>Boat Transporting</FooterLink>
            <FooterLink href="https://www.boatsearch.earth/free-boat-consultation/" target='about_blank'>Boat Consultation</FooterLink>
            <FooterLink href="https://www.boatsearch.earth/boat-training/" target='about_blank'>Boating Training</FooterLink>
            <FooterLink href="https://www.boatsearch.earth/boat-negotiation/" target='about_blank'>Negotiation Services</FooterLink>
		</Column> */}
		<Column>
			<Heading>About us</Heading>
			<FooterLink href="https://www.boatsearch.earth/about-us/" target='about_blank'>About Us</FooterLink>
			<FooterLink href="https://www.boatsearch.earth/articles-and-advice/" target='about_blank'>Articles And Advice</FooterLink>
			<FooterLink href="https://www.boatsearch.earth/privacy-policy/" target='about_blank'>Privacy Policy</FooterLink>
		</Column>
		<Column>
			<Heading>Contact Us</Heading>
			<FooterLink>Phone: 03333 058 424</FooterLink>
			<FooterLink>info@boatsearch.earth</FooterLink>
		</Column>
		<Column>
			<Heading>Follow Us</Heading>
			<FooterLink href="https://www.facebook.com/goearthltd" target='about_blank'>
			<i className="fab fa-facebook-f">
				<span style={{ marginLeft: "10px" }}>
				<FacebookIcon/>
				</span>
			</i>
			</FooterLink>
			<FooterLink href="https://www.instagram.com/goearthltd/" target='about_blank'>
			<i className="fab fa-instagram">
				<span style={{ marginLeft: "10px" }}>
				<InstagramIcon/>
				</span>
			</i>
			</FooterLink>
			<FooterLink href="https://twitter.com/GoEarthLtd" target='about_blank'>
			<i className="fab fa-twitter">
				<span style={{ marginLeft: "10px" }}>
				<TwitterIcon/>
				</span>
			</i>
			</FooterLink>
			<FooterLink href="https://www.youtube.com/channel/UCzxu808uVIcDdi1wXBWb31Q" target='about_blank'>
			<i className="fab fa-youtube">
				<span style={{ marginLeft: "10px" }}>
				<YouTubeIcon/>
				</span>
			</i>
			</FooterLink>
            <FooterLink href="https://www.linkedin.com/company/go-earth-ltd/" target='about_blank'>
			<i className="fab fa-youtube">
				<span style={{ marginLeft: "10px" }}>
				<LinkedInIcon/>
				</span>
			</i>
			</FooterLink>
		</Column>
		</Row>
		<div className="margin">
		<Divider/>
		</div>
			<div className="space-evenly">
				{/* <FooterLink href="https://www.boatsearch.earth/" target="about_blank"><img width="180" height="67" className="margin" src="https://www.boatsearch.earth/wp-content/uploads/2022/01/Final-Go-Earth-Logo-1.svg"></img></FooterLink> */}
				<FooterLink href="https://britishmarine.co.uk/About-us" target="about_blank"><img width="180" height="67" className="margin" src="https://www.boatsearch.earth/wp-content/uploads/2022/04/bmf-logo-e1649082620529.png"></img></FooterLink>
				<FooterLink href="https://uk.trustpilot.com/review/boatsearch.earth" target="about_blank"><img width="180" height="67" className="margin" src="https://www.boatsearch.earth/wp-content/uploads/2022/02/Trust-Pilot-45.png"></img></FooterLink>
			</div>
	</Container>
	</Box>
);
};
export default Footer;
