import 'bootstrap/dist/css/bootstrap.css';
import { useState } from 'react';
import { getData } from '../utils/utils';
import * as React from 'react';
import PropTypes from 'prop-types';

// Material Ui Imports
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { Link } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

        // For Overflowing table text
        function isOverflown(element) {
            return (
            element.scrollHeight > element.clientHeight ||
            element.scrollWidth > element.clientWidth
            );
        }
        
        const GridCellExpand = React.memo(function GridCellExpand(props) {
            const { width, value } = props;
            const wrapper = React.useRef(null);
            const cellDiv = React.useRef(null);
            const cellValue = React.useRef(null);
            const [anchorEl, setAnchorEl] = React.useState(null);
            const [showFullCell, setShowFullCell] = React.useState(false);
            const [showPopper, setShowPopper] = React.useState(false);
        
            const handleMouseEnter = () => {
            const isCurrentlyOverflown = isOverflown(cellValue.current);
            setShowPopper(isCurrentlyOverflown);
            setAnchorEl(cellDiv.current);
            setShowFullCell(true);
            };
        
            const handleMouseLeave = () => {
            setShowFullCell(false);
            };
        
            React.useEffect(() => {
            if (!showFullCell) {
                return undefined;
            }
        
            function handleKeyDown(nativeEvent) {
                // IE11, Edge (prior to using Bink?) use 'Esc'
                if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
                }
            }
        
            document.addEventListener('keydown', handleKeyDown);
        
            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
            }, [setShowFullCell, showFullCell]);
        
            return (
            <Box
                ref={wrapper}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                alignItems: 'center',
                lineHeight: '24px',
                width: 1,
                height: 1,
                position: 'relative',
                display: 'flex',
                }}
            >
                <Box
                ref={cellDiv}
                sx={{
                    height: 1,
                    width,
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                }}
                />
                <Box
                ref={cellValue}
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                {value}
                </Box>
                {showPopper && (
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{ width, marginLeft: -17 }}
                >
                    <Paper
                    elevation={1}
                    style={{ minHeight: wrapper.current.offsetHeight - 3 }}
                    >
                    <Typography variant="body2" style={{ padding: 8 }}>
                        {value}
                    </Typography>
                    </Paper>
                </Popper>
                )}
            </Box>
            );
        });
        
        GridCellExpand.propTypes = {
            value: PropTypes.string.isRequired,
            width: PropTypes.number.isRequired,
        };
        
        function renderCellExpand(params) {
            return (
            <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
            );
        }
        
        renderCellExpand.propTypes = {
            /**
             * The column of the row that the current cell belongs to.
             */
            colDef: PropTypes.object.isRequired,
            /**
             * The cell value, but if the column has valueGetter, use getValue.
             */
            value: PropTypes.string,
        };
        
        const columns = [
            { field: 'col1', headerName: 'Column 1', width: 80, renderCell: renderCellExpand },
            {
            field: 'col2',
            headerName: 'Column 2',
            width: 100,
            renderCell: renderCellExpand,
            },
            {
            field: 'col3',
            headerName: 'Column 3',
            width: 150,
            renderCell: renderCellExpand,
            },
        ];
    // For Overflowing table text

function SourcesOfFinance() {
    const [search, setSearch] = useState([]);
    const [loading,setLoading] = useState(false);

    // Used for validated requests (so unauthorised users cant steal data)
    const authorizeToken = localStorage.getItem('MyToken')

    const searchHandler = async () => {
        const res = await getData(`${process.env.REACT_APP_REST_API}sources-of-finance`, authorizeToken)
        setSearch(res)
    }
    React.useEffect(() => {
        searchHandler()
    },[])

    if( !search.length > 0 || loading){
        return (
            <div className="middlePage">
                <Box sx={{ display: 'flex' }}>
                <CircularProgress />
                </Box>
            </div>
        )
    }
    const columns = [
        {field:'CompanyName', headerName:'Company Name', flex:1, minWidth:300,renderCell: renderCellExpand,},
        {field:'Email', headerName:'Email', flex:1, minWidth:300,renderCell: renderCellExpand,},
        {field:'Website', headerName:'Website', flex:1, renderCell: (cellValues) => {
            return <Link href={`${cellValues.row.Website}`} target='_blank'>{cellValues.row.Website}</Link>;
        }, minWidth:250},
        {field:'ContactName', headerName:'Name', flex:1, minWidth:100,renderCell: renderCellExpand,},
        {field:'ContactPhone', headerName:'Contact Phone', flex:1, minWidth:200,renderCell: renderCellExpand,},
        {field:'ContactMobile', headerName:'Contact Mobile', flex:1, minWidth:200,renderCell: renderCellExpand,},
        {field:'Town', headerName:'Town', flex:1, minWidth:150,renderCell: renderCellExpand,},
        {field:'County', headerName:'County', flex:1, minWidth:100,renderCell: renderCellExpand,},    
        {field:'CountryName', headerName:'Country', flex:1, minWidth:100,renderCell: renderCellExpand,},
        {field:'AddressLine1', headerName:'AddressLine1', flex:1, minWidth:200,renderCell: renderCellExpand,},
        {field:'AddressLine2', headerName:'AddressLine2', flex:1, minWidth:200,renderCell: renderCellExpand,},
        {field:'AddressLine3', headerName:'AddressLine3', flex:1, minWidth:200,renderCell: renderCellExpand,},
        {field:'PostcodeOrZip', headerName:'PostCode', flex:1, minWidth:100,renderCell: renderCellExpand,},
    ]

    return (
            <div>
                <h5 className='center margin'>Sources Of Finance</h5>
            <div style={{ height: '75vh', width: '100%' }}>
                <DataGrid
                    rows={search}
                    columns={columns}
                    getRowId={e => e.FinanceSourceID}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    checkboxSelection
                    disableSelectionOnClick
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    sx={{
                        '& .MuiDataGrid-cell': {
                            overflow: "hidden",
                            whiteSpace: "break-spaces",
                            fontSize:13,
                            fontWeight:600
                        },
                        '& .MuiDataGrid-columnHeader':{
                            fontSize:14,
                            fontWeight:600
                        }
                    }}
                    />
            </div>
        </div>
    )
}

export default SourcesOfFinance;