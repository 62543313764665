import {useState, useEffect} from 'react';
import {tokenCheck,signInFetch} from '../utils/utils'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BestSoftwareMenu from './BestSoftwareMenu';
import { Divider } from '@mui/material';

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordType,setPasswordType] = useState("password");

    // Alert Handlers
    const [show,setShow] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showSuccess,setShowSuccess] = useState(false)
    const handleShowSuccess = () => setShowSuccess(true);
    const handleCloseSuccess = () => setShowSuccess(false);


    // Login Handler
    const signInHandler = async (e) => {
        e.preventDefault()
        try {
            const returnValue = await signInFetch(email, password);
            setEmail(returnValue.Email);
            setPassword(returnValue.Password);
            if(localStorage.getItem('MyToken') === 'undefined'){
                localStorage.clear('MyToken')
                handleCloseSuccess()
                handleShow()
                setEmail("")
                setPassword("")
            }
            else{
                handleClose()
                handleShowSuccess()
                setTimeout(() => {window.location.reload()},1000)
            }
        } catch (error) {
            console.log(error);
            alert("Error");
        }   
    };

    // Password Show and Hide Handler
    const passShowHandler = () => {
        if(passwordType === "text"){
            setPasswordType("password")
        }else{
            setPasswordType("text")
        }
    };

    return (
        <div>
            <h1 className='center bluebg heading'>Best Web Version</h1>
            <BestSoftwareMenu />
            <Divider/>
            <div className="wrapper">
                <div className="logo"><img src="https://yt3.ggpht.com/HWfTl3On_as01ml97hhj8YKSo3P7DQ_HWffmgEZ_QmROI_bSNMeOsCUL4qBpLSu6uqdj81sAgw=s900-c-k-c0x00ffffff-no-rj" alt=""/></div>
                <h2 className="text-center mt-4 name">Please Login</h2>
                <p className="text-center mt-4">Please note that this version of BEST is only available to SuperUser accounts</p>
                <form className="p-3 mt-3" onSubmit={signInHandler}>
                    <div className="form-field d-flex align-items-center"><input id="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}/></div>
                    <div className="form-field d-flex align-items-center"><input id="password" type={passwordType} placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} /><div className="pointer">{passwordType === "password" ? <VisibilityOffIcon className='visIconStyle' onClick={passShowHandler}/> : <VisibilityIcon className="visIconStyle" onClick={passShowHandler}/>}</div></div>
                    <button className="btn" type="submit">
                    Sign In
                    </button>
                </form>
                <Link to="/forgot-password" className='flexCenter'>Forgot Your Password?</Link>
                <Alert show={showSuccess} variant="success" className='center margin'>Login Successful</Alert>
                <Alert show={show} variant="danger" className='center margin'>Invalid Login Attempt, Please try again</Alert>
            </div>
        </div>
    )
}

export default Login;